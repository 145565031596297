import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { useHistory } from "react-router-dom";
import SubHeader from '../../Admin/comp/SubHeader';


function Profile() {

  const history = useHistory();
    
  const [user, setUser] = useState([]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {setUser(JSON.parse(loggedInUser));}
  }, []);


  return (
    <>
<div className="main-wrapper">
  <div className="profile">
  <div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">
  
<SubHeader/>

<div className="sliding custom-title">Profile</div>
<div className="right mr-2">
<a href="#" className="link icon-only" data-toggle="modal" data-target="#edit-profile">
<i className="far fa-edit"></i>
</a>
</div>
</div>
</div>

    <div className="page-content mt-0">
      <div className="profile-header">
        <div className="pro-img-box">
          <img alt="" src="/assets/img/user.jpg" />
          <div className="pro-img-upload">
            <input type="file" className="upload" />
          </div>
        </div>
        <div className="pro-user-det">
          <div className="profile-name">{user.NAME}</div>
          <div className="profile-designation">{user.ACCESS}</div>
        </div>
      </div>
      <ul id="tabs" className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <a id="Overview" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Overview</a>
        </li>
      </ul>
      <div id="content" className="tab-content" role="tablist">
        <div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Overview">
          <div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
            <div className="list">
              <div className="card-box">
                <ul>
                  <li>
                    <div className="item-content">
                      <div className="item-inner d-flex justify-content-between">
                        <div className="item-title">Phone</div>
                        <div className="item-after text-muted">{user.PHONE}</div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item-content">
                      <div className="item-inner d-flex justify-content-between">
                        <div className="item-title">Email</div>
                        <div className="item-after text-muted">{user.EMAIL}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="pane-B" className="tab-pane fade" role="tabpanel" aria-labelledby="Education">
          <div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
            <div className="card-box">
              <div className="experience-box">
                <ul className="experience-list">
                  <li>
                    <div className="experience-user">
                      <div className="before-circle" />
                    </div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        <span className="name">International College of Arts and Science (UG)</span>
                        <div>Bsc Computer Science</div>
                        <span className="time">2000 - 2003</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="experience-user">
                      <div className="before-circle" />
                    </div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        <span className="name">International College of Arts and Science (PG)</span>
                        <div>Msc Computer Science</div>
                        <span className="time">2000 - 2003</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="pane-C" className="tab-pane fade" role="tabpanel" aria-labelledby="Experience">
          <div id="collapse-C" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-C">
            <div className="card-box">
              <div className="experience-box">
                <ul className="experience-list">
                  <li>
                    <div className="experience-user">
                      <div className="before-circle" />
                    </div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        <span className="name">Web Designer at Zen Corporation</span>
                        <span className="time">Jan 2013 - Present (5 years 2 months)</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="experience-user">
                      <div className="before-circle" />
                    </div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        <span className="name">Web Designer at Ron-tech</span>
                        <span className="time">Jan 2013 - Present (5 years 2 months)</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="experience-user">
                      <div className="before-circle" />
                    </div>
                    <div className="experience-content">
                      <div className="timeline-content">
                        <span className="name">Web Designer at Dalt="" Technology</span>
                        <span className="time">Jan 2013 - Present (5 years 2 months)</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade custom-modal-popup" id="edit-profile" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">Edit Profile</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="list no-hairlines custom-form mt-0">
                <h6>Basic Informations</h6>
                <ul>
                  <li className="item-content item-input mt-3">
                    <div className="item-inner">
                      <div className="item-title item-label">First Name</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Last Name</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Birth Date</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Gender</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                </ul>
                <h6>Contact Informations</h6>
                <ul>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Address</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">State</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Country</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Pincode</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Phone Number</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Address</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                </ul>
                <h6>Education Informations</h6>
                <ul>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Institution</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Subject</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Starting Date</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Complete Date</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Degree</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Grade</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-input-wrap m-t-10">
                        <div className="add-more">
                          <a className="add-more-btn" href="#"><i className="fa fa-plus" /> Add More Institute</a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <h6>Experience Informations</h6>
                <ul>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Company Name</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Location</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Job Position</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Period From</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-title item-label">Period To</div>
                      <div className="item-input-wrap">
                        <input type="text" />
                        <span className="input-clear-button" />
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-input-wrap m-t-10">
                        <div className="add-more">
                          <a className="add-more-btn" href="#"><i className="fa fa-plus" /> Add More Experience</a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="item-content item-input">
                    <div className="item-inner">
                      <div className="item-input-wrap m-t-10">
                        <div className="row">
                          <div className="col-12">
                            <a className="tab-link button button-big button-purple w-100 btn" href data-dismiss="modal" aria-label="Close">Send</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>

  );
}

export default Profile;
