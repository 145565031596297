
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MAIN_URL } from '../Global/Urls';
import axios from 'axios';
import SubHeader from './comp/SubHeader';

function CompanyListExpiry() {
  
  const [expiredList, setExpiredList] = useState([]);
  const [expiredCount, setExpiredCount] = useState(0);
  const [thisMonthList, setThisMonthList] = useState([]);
  const [thisMonthCount, setThisMonthCount] = useState(0);
  const [upcommingList, setUpcommingList] = useState([]);
  const [upcommingCount, setUpcommingCount] = useState(0);



  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {
      expiredCompany();
      thisMonth();
      Upcomming();
    }

  }, []);
  

  function expiredCompany() {
    const Url = MAIN_URL+`?q=ucompany/company_validity_list/e`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setExpiredList(response.data.RS);
          setExpiredCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }



   function thisMonth() {
    const Url = MAIN_URL+`?q=ucompany/company_validity_list/t`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setThisMonthList(response.data.RS);
          setThisMonthCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

 
   function Upcomming() {
    const Url = MAIN_URL+`?q=ucompany/company_validity_list/u`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setUpcommingList(response.data.RS);
          setUpcommingCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }
  

  return (
    <>

         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">Company Install List</div>

</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "33.32%"}}>
<a id="Today" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">
This Month
<br/>
{thisMonthCount}
</a>
</li>
<li className="nav-item" style={{width: "33.32%"}}>

<a id="Tomorrow" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">
Expired
<br/>
{expiredCount}
</a>
</li>
<li className="nav-item" style={{width: "33.32%"}}>

<a id="Upcoming" href="#pane-U" className="nav-link" data-toggle="tab" role="tab">
Upcomming
<br/>
{upcommingCount}
</a>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>

<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Today">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">





{thisMonthList.map(item => {
    // Calculate the difference in milliseconds
    const differenceMs = new Date(item.VALID_TILL / 1) - Date.now();
    // Convert milliseconds to days
    const daysLeft = Math.ceil(Math.abs(differenceMs) / (1000 * 60 * 60 * 24));
    // Determine if the item is expired
    const isExpired = differenceMs < 0;

    return (
        <li className="col-12" key={item.ID} style={{ marginTop: "-1px", borderTop: "1px solid red" }}>
            <Link to={`/admin/companyDetails/${item.ID}`}>
                <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{ width: "100%" }}>
                        <div className="item-title-row d-flex align-items-center justify-content-between">
                            <div className="item-title"><strong>{item.COMPANY_NAME.toUpperCase()}</strong></div>
                        </div>
                        <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                            <span className="leave-type">{item.APP_NAME}</span>
                            {/* Displaying days left or "Expired" */}
                            {isExpired ? (
                                <span className="badge badge-danger float-right leave-status">Expired</span>
                            ) : (
                                <span className="badge badge-danger float-right leave-status">{daysLeft} days left</span>
                            )}
                        </div>
                        <div className="item-text leave-days">Phone: {item.PHONE_NO}</div>
                        <div className="item-text leave-days">{item.ADDRESS}</div>
                        <div className="item-text leave-days">{item.CITY} || {item.STATE}</div>
                        <div className="item-text leave-days">Valid: {(new Date(item.VALID_TILL / 1)).toLocaleString()}</div>
                        <div className="item-text leave-days">Sale By: {item.SALES_PERSON}</div>
                 <div className="item-text leave-days">Support By: {item.CONSULTANT}</div>
                    </div>
                </div>
            </Link>
        </li>
    );
})}
   
                          

</ul>
</div>
</div>
</div>
</div>




<div id="pane-B" className="tab-pane fade" role="tabpanel" aria-labelledby="Tomorrow">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">


  



                                        


{expiredList.map(item => {
    // Calculate the difference in milliseconds
    const differenceMs = new Date(item.VALID_TILL / 1) - Date.now();
    // Convert milliseconds to days
    const daysLeft = Math.ceil(Math.abs(differenceMs) / (1000 * 60 * 60 * 24));
    // Determine if the item is expired
    const isExpired = differenceMs < 0;

    return (
        <li className="col-12" key={item.ID} style={{ marginTop: "-1px", borderTop: "1px solid red" }}>
            <Link to={`/admin/companyDetails/${item.ID}`}>
                <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{ width: "100%" }}>
                        <div className="item-title-row d-flex align-items-center justify-content-between">
                            <div className="item-title"><strong>{item.COMPANY_NAME.toUpperCase()}</strong></div>
                        </div>
                        <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                            <span className="leave-type">{item.APP_NAME}</span>
                            {/* Displaying days left or "Expired" */}
                            {isExpired ? (
                                <span className="badge badge-danger float-right leave-status">Expired</span>
                            ) : (
                                <span className="badge badge-danger float-right leave-status">{daysLeft} days left</span>
                            )}
                        </div>
                        <div className="item-text leave-days">Phone: {item.PHONE_NO}</div>
                        <div className="item-text leave-days">{item.ADDRESS}</div>
                        <div className="item-text leave-days">{item.CITY} || {item.STATE}</div>
                        <div className="item-text leave-days">Valid: {(new Date(item.VALID_TILL / 1)).toLocaleString()}</div>
                        <div className="item-text leave-days">Sale By: {item.SALES_PERSON}</div>
                 <div className="item-text leave-days">Support By: {item.CONSULTANT}</div>
                    </div>
                </div>
            </Link>
        </li>
    );
})}

                                        
                 

</ul>
</div>
</div>
</div>
</div>





<div id="pane-U" className="tab-pane fade" role="tabpanel" aria-labelledby="Upcoming">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">


  



                                        


{upcommingList.map(item => {
    // Calculate the difference in milliseconds
    const differenceMs = new Date(item.VALID_TILL / 1) - Date.now();
    // Convert milliseconds to days
    const daysLeft = Math.ceil(Math.abs(differenceMs) / (1000 * 60 * 60 * 24));
    // Determine if the item is expired
    const isExpired = differenceMs < 0;

    return (
        <li className="col-12" key={item.ID} style={{ marginTop: "-1px", borderTop: "1px solid red" }}>
            <Link to={`/admin/companyDetails/${item.ID}`}>
                <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{ width: "100%" }}>
                        <div className="item-title-row d-flex align-items-center justify-content-between">
                            <div className="item-title"><strong>{item.COMPANY_NAME.toUpperCase()}</strong></div>
                        </div>
                        <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                            <span className="leave-type">{item.APP_NAME}</span>
                            {/* Displaying days left or "Expired" */}
                            {isExpired ? (
                                <span className="badge badge-danger float-right leave-status">Expired</span>
                            ) : (
                                <span className="badge badge-danger float-right leave-status">{daysLeft} days left</span>
                            )}
                        </div>
                        <div className="item-text leave-days">Phone: {item.PHONE_NO}</div>
                        <div className="item-text leave-days">{item.ADDRESS}</div>
                        <div className="item-text leave-days">{item.CITY} || {item.STATE}</div>
                        <div className="item-text leave-days">Valid: {(new Date(item.VALID_TILL / 1)).toLocaleString()}</div>
                        <div className="item-text leave-days">Sale By: {item.SALES_PERSON}</div>
                 <div className="item-text leave-days">Support By: {item.CONSULTANT}</div>
                    </div>
                </div>
            </Link>
        </li>
    );
})}

                                        
                 

</ul>
</div>
</div>
</div>
</div>

</div>


</div>
</div>
</div>

   



    </>

  );
}

export default CompanyListExpiry;





