
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { useParams, useHistory } from 'react-router-dom';
import SubHeader from '../../Admin/comp/SubHeader';

function TicketClosedEntry() {


  const { id } = useParams();
  const history = useHistory();

  const [lead, setLead] = useState([]);
  const [user, setUser] = useState([]);
  const [load, setLoad] = useState(false);
  const [description, setDescription] = useState("");
  const [text, setText] = useState("Close Ticket");

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);
    setUser(userData);
  
    if (parseInt(userData.ID)>0) {
          getOrderMaster();
        }

  }, []);
  



   
  function getOrderMaster() {
      const Url = MAIN_URL+`?q=uticket/d/${id}`;
      axios.get(Url)
      .then(response => {
          if(response.data.SUCCESS > 0){
            setLead(response.data.RS);
            setLoad(true);
          }
      });

   }

                    
  const CreateItem = (e) => {
    e.preventDefault();
    const recipeUrl = MAIN_URL+'?q=ui/edit';
    let formData = new FormData();    //formdata object

    formData.append('ID', lead.ID);
    formData.append('T_NAME', "TICKETS");
    formData.append('DESCRIPTION_CLOSE', description);
    formData.append('DONE_BY', user.ID);
    formData.append('MODIFY_BY', user.ID);
    formData.append('MODIFY_ON', parseInt(Date.now()/1000));
    formData.append('DONE_ON', parseInt(Date.now()/1000));
    formData.append('IS_DONE', "Y");
    formData.append('STATUS', 1);

    
      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Date Saved");
            }
        })
        .catch(error => {
            console.log(error);
    });

  }




 

  return (
    <>

    {load? (
      <>
          <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">Ticket Close</div>
</div>
</div>

<div className="page-content" style={{marginTop: "-16px"}}>

<div className="list">



<div className="card-box" style={{marginTop: "-15px"}}>
<ul>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Name</div>
<div className="item-after text-muted">{lead.CNAME}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Phone</div>
<div className="item-after text-muted">{lead.CPHONE}</div>
</div>
</div>
</li>

{lead.EMAIL? (
  <>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Email</div>
<div className="item-after text-muted">{lead.EMAIL}</div>
</div>
</div>
</li>

  </>
) : (
  <>
  </>
)}


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Designation</div>
<div className="item-after text-muted">{lead.DESIGNATION}</div>
</div>
</div>
</li>

</ul>
</div>





<div className="card-box" style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Company</div>
<div className="item-after text-muted">{lead.COMPANY_NAME}</div>
</div>
</div>
</li>







</ul>
</div>



<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Ticket Info</strong></p>
</div>
</div>
<div className="card-box"  style={{marginTop: "-15px"}}>
<ul>
  


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Scheduled On</div>
<div className="item-after text-muted">{(new Date(lead.SCHEDULE_DATE*1000)).toLocaleString()}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Subject :</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">{lead.SUBJECT}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Details :</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">{lead.DESCRIPTION}</div>
</div>
</div>
</li>


</ul>
</div>


</div>

  
  <div className="list no-hairlines custom-form" style={{marginTop: "-10px"}}>



    
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">




  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Note</div>
  <div className="item-input-wrap">
  <textarea type="text" onChange={e => setDescription(e.target.value)} value={description}/>
  </div>
  </div>
  </li>


  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">
  <button className="button button-big button-purple" onClick={CreateItem} type="button">{text}</button>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>

</div>
</div>
      </>
    ) : (
      <></>
      )}
   



    </>

  );
}

export default TicketClosedEntry;





