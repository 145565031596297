import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { Link, useHistory } from "react-router-dom";
import GuestHeader from '../Global/Header';

function Home() {


   const [user, setUser] = useState([]);
   const [todayList, setTodayList] = useState([]);
   const [todaySummary, setTodaySummary] = useState([]);
   const [todaySummaryLoded, setTodaySummaryLoded] = useState(false);
   const [todayLoaded, setTodayLoaded] = useState(false);
   const [todayCount, setTodayCount] = useState(0);
   const [todayTicketList, setTodayTicketList] = useState([]);
   const [todayTicketCount, setTodayTicketCount] = useState(0);
   const [convertedAmount, setConvertedAmount] = useState(0);
   const [convertedClient, setconvertedClient] = useState(0);
   const [todayTicketLoded, setTodayTicketLoded] = useState(false);
   const [thisMonthTicketClosed, setThisMonthTicketClosed] = useState(0);
   const [totalTicketPending, setTotalTicketPending] = useState(0);
   const history = useHistory();
   const [admin, setAdmin] = useState(false);
   const [sales, setSales] = useState(false);
   
   useEffect(() => {
     const userDetails = localStorage.getItem("user");

     if(userDetails){
       const sData = JSON.parse(userDetails);  
       setUser(sData);
       localStorage.setItem("bm_user", JSON.stringify(sData));

       if(parseInt(sData.ACCESS)===5 || parseInt(sData.ACCESS)===3){
          setAdmin(true);
      }

      
      if(sData.TYPE==="SALES"){
        setSales(true);
    }

       today(parseInt(sData.ID));
       todaySummaryLoader(parseInt(sData.ID));
       todayTicket(parseInt(sData.ID));
       thisMonthConverted(parseInt(sData.ID));
       thisMonthClosedTicketed(parseInt(sData.ID));
       totalTicketPendingReq(parseInt(sData.ID));
       
      }
     else{
      history.push("/user/login");
    }
   }, []);
 
 
 
   function today(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_list_open_preod/${ok}/t`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodayList(response.data.RS);
          setTodayCount(response.data.RS.length);
          if(response.data.RS.length>0){
            setTodayLoaded(true);
          }
        }
    })
    .catch(error => {
        console.log(error);
    });

   }


 
   function todaySummaryLoader(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_summary_open_preod/${ok}/t`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodaySummary(response.data.RS);
          if(response.data.RS.length>0){
            setTodaySummaryLoded(true);
          }
        }
    })
    .catch(error => {
        console.log(error);
    });

   }
   
 
   function todayTicket(ok) {
    const Url = MAIN_URL+`?q=uactivity/ticket_list_open_preod/${ok}/t`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodayTicketList(response.data.RS);
          setTodayTicketCount(response.data.RS.length);
          if(response.data.RS.length>0){
            setTodayTicketLoded(true);

          }
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   
  function logoutUsers(){
    localStorage.removeItem("bm_user");
    localStorage.removeItem("user");
    history.go(0);
  }


  
  function thisMonthConverted(ok) {
    const Url = MAIN_URL+`?q=uleads/converted_lead_by_user/m/${ok}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setConvertedAmount(response.data.RS.AMOUNT);
          setconvertedClient(response.data.RS.ID);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }


   
  function thisMonthClosedTicketed(ok) {
    const Url = MAIN_URL+`?q=uticket/ticketed_closed_by_user/m/${ok}`;
    axios.post(Url)
    .then(response => {
          console.log(response.data.RS.TOTAL);
          setThisMonthTicketClosed(response.data.RS.TOTAL);
    })
    .catch(error => {
        console.log(error);
    });

   }


   
   function totalTicketPendingReq(ok) {
    const Url = MAIN_URL+`?q=uactivity/ticket_list_open_preod/${ok}/m`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTotalTicketPending(response.data.RS.length);
          
        }
    })
    .catch(error => {
        console.log(error);
    });

   }


  return (
    <>
    <div id="main" class="main-wrapper">

<GuestHeader/>



<div className="home">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">
<div className="left">
<a href="#" className="link icon-only"><img src="/assets/img/logo.png" alt="" className=""/></a>
</div>
<div className="right d-flex">
<a href="#" data-toggle="dropdown" aria-expanded="true" className="link dropdown-link"><i className="material-icons">more_vert</i></a>
<div className="dropdown-menu dropdown-menu-right header_drop_icon">
<Link to="/user/profile" className="dropdown-item">My Profile</Link>
<a onClick={()=> logoutUsers()} className="dropdown-item">Log Out</a>
</div>
</div>
</div>
</div>


<div className="page-content" style={{marginTop:"0px"}}>
<div className="container" style={{padding: '0px'}}>
<div className="dashboard-area">


  {sales ? (
    <>
    
  <div className="row">
    <div className="col-12">
    <div className="dash-widget" style={{textAlign: 'left', marginBottom: '0px'}}>
          <div className="dash-widget-info">
            <h4>{convertedAmount}</h4>
            <p>Converted Amount</p>
          </div>
        </div>
    </div>
  </div>

  <div className="row">
    <div className="col-12">
    <div className="dash-widget" style={{textAlign: 'left', marginBottom: '0px'}}>
          <div className="dash-widget-info">
          <h4>{convertedClient}</h4>
          <p>Converted Client</p>
          </div>
        </div>
    </div>
  </div>

    </>

  ) : 
  (
    <>
    

  

    <Link to="/home/ticket">
  <div className="row">
    <div className="col-12">
      <a href="projects.html">
      <div className="dash-widget" style={{textAlign: 'left', marginBottom: '0px'}}>
          <div className="dash-widget-info">
            <h4>{totalTicketPending}</h4>
            <p>Total Pending Ticket</p>
          </div>
        </div>
      </a>
    </div>
  </div>
  </Link>

  
    </>
  )
}


{todayLoaded ? (
  <>
<div className="row" id="tt">
<div className="col-12">
<div className="chart-box" style={{textAlign: 'left'}}>
<h3 className="title is-3" style={{verticalAlign:"center"}}>Today Activity ({todayCount}) <Link to="/home/activity" className="btn btn-success pull-right">View All</Link></h3>
</div>

</div>

</div>
<div className="card-box" style={{padding: "0px", marginTop:"-20px"}}>
  
<div className="experience-box">
<ul className="experience-list">




{todayList.map(item => (
                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/activity/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.LEAD_NAME.toUpperCase()}</strong></div>
                    {item.START_TIME>Date.now()/1000 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                      <span className="leave-type">{item.SUBJECT===null || item.SUBJECT==="" ? item.TYPE : item.SUBJECT}</span> 
                      {item.START_TIME>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.START_TIME*1000)).toLocaleString()}</div>
                    <div className="item-text leave-days">For : {item.OWNER_NAME}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}
</ul>
</div>
</div>

  </>
) : (
<>
  
  </>
) }


{todaySummaryLoded ? (
<>
<div className="row" id="tt">
<div className="col-12">
<div className="chart-box" style={{textAlign: 'left'}}>
<h3 className="title is-3">Activity Summary</h3>

<div class="list links-list settings-list">
<ul>
{todaySummary.map(item => (

<li style={{marginLeft: "-15px"}}><Link to="/home/activity"><b>{item.TYPE}</b> 
<span className="badge badge-danger float-right leave-status pull-right">{item.TOTAL}</span>

</Link></li>
                                        ))}

</ul>
</div>

</div>

</div>

</div>
</>
) :
(
  <>

  </>
)
}

{todayTicketLoded ? (
  <>
<div className="row" id="tt">
<div className="col-12">
<div className="chart-box" style={{textAlign: 'left'}}>
<h3 className="title" style={{horizontalAlign: "center"}}>Today Ticket ({todayTicketCount}) <Link to="/home/ticket" className="btn btn-success pull-right">View All</Link></h3>
</div>

</div>

</div>

<div className="card-box" style={{padding: "0px", marginTop:"-20px"}}>
  
<div className="experience-box">
<ul className="experience-list">




{todayTicketList.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/ticket/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.LEAD_NAME.toUpperCase()}</strong></div>
                    {item.SCHEDULE_DATE>Date.now()/1000 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">
                      {item.SUBJECT==="" || item.SUBJECT===null ? (
                        <>
                        </>
                      ) : (
                        <>
                        {item.SUBJECT}<br/>
                        </>
                      )}
                      {item.DESCRIPTION}</span> 

                      {item.SCHEDULE_DATE>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.SCHEDULE_DATE*1000)).toLocaleString()}</div>
                    <div className="item-text leave-days">For : {item.OWNER_NAME}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}
</ul>
</div>
</div>

  </>
) : (
  <>
  </>
)}


<div className="row" id="tt">
<div className="col-12">
<div className="chart-box" style={{textAlign: 'left'}}>
<h3 className="title is-3">Menu</h3>

<div class="list links-list settings-list">
<ul>
<li style={{marginLeft: "-15px"}}><Link to="/home/activity"><b>Activity List</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/home/ticket"><b>Ticket List</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/lead/list"><b>Leads List</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/home/company-list"><b>Company Report</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/home/company-list-expiry"><b>Company [Expiry]</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/activity/pending"><b>Pending Activity</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/deal/list"><b>Deals</b></Link></li>
{admin? (
  <>
<li style={{marginLeft: "-15px"}}><Link to="/admin/home"><b>Admin Panel</b></Link></li>
  </>
) : (<></>)}
</ul>
</div>

</div>

</div>

</div>


</div>
</div>
</div>

</div>
</div>

    </>

  );
}

export default Home;
