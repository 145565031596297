
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { Link, useHistory, useParams } from "react-router-dom";
import SubHeader from '../../Admin/comp/SubHeader';

function NewActivity() {

  const { id } = useParams();

  const history = useHistory();

  const [user, setUser] = useState([]);
  const [text, setText] = useState("Save");
  const [subject, setsubject] = useState("");
  const [type, setType] = useState("WhatsApp");
  const [purpose , setPurpose ] = useState("Prospecting");
  const [startTime, setStartTime] = useState("");
  const [priority, setPriority] = useState("Low priority");


  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);
  
    if (parseInt(userData.ID)>0) {
      setUser(userData);
    }

  }, []);
  
  
  const chengeType = (e) => {
    const type = e.target.value;
    setType(type);
  };

     
  const onChangePurpose = (e) => {
    const type = e.target.value;
    setPurpose(type);
  };


       
  const onChangePriority = (e) => {
    const type = e.target.value;
    setPriority(type);
  };




   useEffect(() => {

     
    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

    if (parseInt(userData.ID)>0) {
          setUser(userData);
        }

     }, []);


 
                 
  const CreateActivity = (e) => {
    e.preventDefault();
    console.log();
  const recipeUrl = MAIN_URL+'?q=ui/save';

    let formData = new FormData();    //formdata object

    formData.append('LEAD_ID', id);
    formData.append('T_NAME', "LEAD_ACTIVITY");
    formData.append('TYPE', type);
    formData.append('SUBJECT', subject);
    formData.append('ACTIVITY_FOR', 1);
    formData.append('ACTIVITY_OWNER', user.ID);
    formData.append('START_TIME', toTimestamp(startTime));
    formData.append('PRIORITY',priority);
    formData.append('CALL_PURPOSE',purpose);
    formData.append('STATUS', 0);
    formData.append('CREATE_BY', user.ID);
    formData.append('CREATE_ON', parseInt(Date.now()/1000));

      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Lead Saved");
              history.push(`/lead/details/${id}`);
            }
        })
        .catch(error => {
            console.log(error);
    });

  }

  function toTimestamp(strDate){ var datum = Date.parse(strDate); return datum/1000;}


  return (
    <>
   
   <div className="main-wrapper">
  <div className="add-client">

  <div className="navbar two-action no-hairline">
  <div className="navbar-inner d-flex align-items-center justify-content-between">
    
  <SubHeader/>

  <div className="sliding custom-title">Add New Activity</div>
  <div className="right">
  </div>
  </div>
  </div>

  <div className="page-content" style={{marginTop: "-16px"}}>
  <div className="list no-hairlines custom-form">
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Activity Subject</div>
  <div className="item-input-wrap">
  <textarea onChange={e => setsubject(e.target.value)} value={subject} type="text"/>
  </div>
  </div>
  </li>


  
  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Activity Type</div>
  <div class="item-input-wrap">
  <select onChange={chengeType}>
  <option value="WhatsApp">WhatsApp</option>
  <option value="Physical Visit">Physical Visit</option>
  <option value="Massage">Massage</option>
  <option value="Email">Email</option>
  <option value="Call">Call</option>
  </select>
  </div>
  </div>
  </li>

  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Purpose </div>
  <div class="item-input-wrap">
  <select onChange={onChangePurpose}>
  <option value="Prospecting">Prospecting</option>
  <option value="Negotiation">Negotiation</option>
  <option value="Demo">Demo</option>
  <option value="Install">Install</option>
  <option value="Support">Support</option>
  <option value="Other">Other</option>
  </select>
  </div>
  </div>
  </li>

  
  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Priority</div>
  <div class="item-input-wrap">
  <select onChange={onChangePriority}>
  <option value="Low priority">Low priority</option>
  <option value="Normal priority">Normal priority</option>
  <option value="High priority">High priority</option>
  <option value="Highest priority">Highest priority</option>
  </select>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Start Time</div>
  <div className="item-input-wrap">
  <input type="datetime-local" onChange={e => setStartTime(e.target.value)} value={startTime}/>
  </div>
  </div>
  </li>
  


  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">
  <button className="button button-big button-purple" onClick={CreateActivity} type="button">{text}</button>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>




  </div>
  </div>

    </>

  );
}

export default NewActivity;





