
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MAIN_URL } from '../Global/Urls';
import axios from 'axios';
import {useHistory} from "react-router-dom";
import SubHeader from './comp/SubHeader';

function LeadReport() {
  
  const history = useHistory();

  
  const [lead, setLead] = useState([]);
  const [todayList, setTodayList] = useState([]);
  const [todayCount, setTodayCount] = useState(0);
  const [tommorrowList, setTommorrowList] = useState([]);
  const [tommorrowCount, setTommorrowCount] = useState(0);
  const [weekList, setweekList] = useState([]);
  const [weekCount, setweekCount] = useState(0);
  const [missed, setMissed] = useState([]);
  const [missedCount, setMissedCount] = useState(0);
  const [load, setLoad] = useState(false);
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };



  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {

      if(parseInt(userData.ACCESS)===3){
        todayp(userData.PARTNER_ID);
        yesterdayp(userData.PARTNER_ID);
        weekp(userData.PARTNER_ID);
        mActivityp(userData.PARTNER_ID);
      }
      else{
        today();
        yesterday();
        week();
        mActivity();
      }
   
    }

  }, []);
  

  function today() {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_admin/t`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodayList(response.data.RS);
          setTodayCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }



   function yesterday() {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_admin/y`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTommorrowList(response.data.RS);
          setTommorrowCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

 
   function week() {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_admin/w`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setweekList(response.data.RS);
          setweekCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }
   
   function mActivity() {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_admin/m`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setMissed(response.data.RS);
          setMissedCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   

  function todayp(id) {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_pratner/t/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodayList(response.data.RS);
          setTodayCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }



   function yesterdayp(id) {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_pratner/y/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTommorrowList(response.data.RS);
          setTommorrowCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

 
   function weekp(id) {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_pratner/w/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setweekList(response.data.RS);
          setweekCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }
   
   function mActivityp(id) {
    const Url = MAIN_URL+`?q=uleads/lead_create_details_pratner/m/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setMissed(response.data.RS);
          setMissedCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

  return (
    <>

         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">Lead Creation Report</div>
<div className="right mr-2">
<a href="#" className="link icon-only" data-toggle="modal" data-target="#edit-profile">
<i className="far fa-edit"></i>
</a>
</div>
</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "25%"}}>
<a id="Today" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Today
</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Tomorrow" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Yesterday
</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Week" href="#pane-C" className="nav-link" data-toggle="tab" role="tab">Week</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Missed" href="#pane-D" className="nav-link" data-toggle="tab" role="tab">Month</a>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>

<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Today">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">


{todayList.map(item => (
                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/lead/list_by_user/${item.CREATE_BY}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.CREATOR_NAME}</strong></div>
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">
                      CODE : {item.CREATE_BY}</span> 

                
                      <span className="badge badge-danger float-right leave-status">{item.TOTAL}</span>
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.MIN*1000)).toLocaleString('en-US',options)} to {(new Date(item.MAX*1000)).toLocaleString('en-US',options)}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}

                                        


</ul>
</div>
</div>
</div>
</div>




<div id="pane-B" className="tab-pane fade" role="tabpanel" aria-labelledby="Tomorrow">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{tommorrowList.map(item => (
                    
                     

                 
                 
                    <li className="col-12"  key={item.ID} style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/lead/list_by_user/${item.CREATE_BY}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.CREATOR_NAME}</strong></div>
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">
                      CODE : {item.CREATE_BY}</span> 

                
                      <span className="badge badge-danger float-right leave-status">{item.TOTAL}</span>
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.MIN*1000)).toLocaleString('en-US',options)} to {(new Date(item.MAX*1000)).toLocaleString('en-US',options)}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}

                                        

</ul>
</div>
</div>
</div>
</div>


<div id="pane-C" className="tab-pane fade" role="tabpanel" aria-labelledby="Tomorrow">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{weekList.map(item => (
                    
                    
                 
                    <li className="col-12"  key={item.ID} style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/lead/list_by_user/${item.CREATE_BY}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.CREATOR_NAME}</strong></div>
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">
                      CODE : {item.CREATE_BY}</span> 

                
                      <span className="badge badge-danger float-right leave-status">{item.TOTAL}</span>
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.MIN*1000)).toLocaleString('en-US',options)} to {(new Date(item.MAX*1000)).toLocaleString('en-US',options)}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                                        
                                        ))}

                                        
</ul>
</div>
</div>
</div>
</div>



<div id="pane-D" className="tab-pane fade" role="tabpanel" aria-labelledby="Missed">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{missed.map(item => (
                    
                     

                 
                    <li className="col-12"  key={item.ID} style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/lead/list_by_user/${item.CREATE_BY}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.CREATOR_NAME}</strong></div>
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">
                      CODE : {item.CREATE_BY}</span> 

                
                      <span className="badge badge-danger float-right leave-status">{item.TOTAL}</span>
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.MIN*1000)).toLocaleString('en-US',options)} to {(new Date(item.MAX*1000)).toLocaleString('en-US',options)}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                                        
                                        ))}


</ul>
</div>
</div>
</div>
</div>


</div>


</div>
</div>
</div>

   



    </>

  );
}

export default LeadReport;





