import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { useHistory } from "react-router-dom";


function Login() {

  const history = useHistory();
    
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loged, setLoged] = useState(false);
  const [loginText, setLoginText] = useState("Enter your username & password to login");


  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {setLoged(true);}

  }, []);


  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };


  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };


  const onLogin = (e) => {
    if(username===""){

    }
    setLoginText("Please Wait");
    e.preventDefault();


    
    const loginUrl = MAIN_URL+'?q=user/sign_in_api';

    const config = {     
      headers: { 'content-type': 'multipart/form-data' }
    }

    
    let formData = new FormData();    //formdata object
    formData.append('phone_no', username);   //append the values with key, value pair
    formData.append('password', password);   //append the values with key, value pair


    axios.post(loginUrl, formData, config)
    .then(response => {
        console.log(response.data);
        if(response.data.SUCCESS > 0){
          localStorage.setItem("user", JSON.stringify(response.data.RS));
          setLoged(true);
          setLoginText("Login Successfull");
          history.push("/");
        }
        else{
          setLoginText("Login unSuccessfull");
        }
    })
    .catch(error => {
        console.log(error);
    });

  }





  return (
    <>
<div className="main-wrapper">
<div className="page no-navbar no-toolbar no-swipeback login-page login d-flex align-items-center vh100">
<div className="page-content w-100">
<div className="account-page">
<div className="account-inner">
<div className="account-center">
<div className="account-content">
<div className="account-logo">
<img alt="" src="/assets/img/logo2.png"/>
</div>
<div className="account-title">
<h3>Login</h3>
</div>
<div className="account-form">
<form>
<div className="input-list">
<input type="text" onChange={onChangeUsername} value={username} placeholder="Username or Email"/>
</div>
<div className="input-list">
<input type="password"  onChange={onChangePassword} value={password} placeholder="Password"/>
</div>

<div className="input-list">
<button className="button button-big account-btn btn w-100" onClick={onLogin}>Login</button>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


    </>

  );
}

export default Login;
