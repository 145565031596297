
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MAIN_URL } from '../Global/Urls';
import axios from 'axios';
import {useHistory} from "react-router-dom";
import SubHeader from './comp/SubHeader';

function LocationList() {
  
  const history = useHistory();
  const { id } = useParams();
  
  
  const [lead, setLead] = useState([]);
  const [todayList, setTodayList] = useState([]);
  const [todayCount, setTodayCount] = useState(0);
  const [load, setLoad] = useState(false);




  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {
      today();
    }

  }, []);
  

  function today() {
    const Url = MAIN_URL+`?q=locations/sub_list/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodayList(response.data.RS);
          setTodayCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }



  return (
    <>

         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">Location List</div>


<div className="right mr-2">
<Link to={`/admin/locationsNew/${id}`} className="link icon-only">
<i className="material-icons">add</i>
</Link>
</div>
</div>
</div>

<div className="page-content mt-0">



<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>

<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Today">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">


{todayList.map(item => (
                 
                 
                 <li className="col-12"  key={item.ID} style={{marginTop: "14px", borderTop: "1px solid red"}}>
                 <div className="item-content d-flex pt-2 pb-2">
                 <div className="item-inner appraisal-col" style={{width: "100%"}}>
                 <div className="item-title-row d-flex align-items-center justify-content-between">
                 <Link  to={`/admin/locationsEdit/${item.ID}`}>
                 <div className="item-title"><strong>{item.NAME.toUpperCase()}</strong></div>
                 </Link>

                 </div>
                 <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                 <span className="leave-type">
                   {item.LINK_NAME}</span> 

             
                   <span className="badge badge-danger float-right leave-status">{item.ID}</span>
                 
                 </div>
                 <Link  to={`/admin/locations/${item.ID}`}>
                 <div className="item-text leave-days">View</div>
                 </Link>
                 </div>
                 </div>
                 </li>
                                        
                                        ))}

                                        


</ul>
</div>
</div>
</div>
</div>



</div>


</div>
</div>
</div>

   



    </>

  );
}

export default LocationList;





