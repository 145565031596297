
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MAIN_URL } from '../Global/Urls';
import axios from 'axios';
import SubHeader from './comp/SubHeader';

function ResellerList() {
  
  const [list, setList] = useState([]);

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);
  
    if (parseInt(userData.ID)>0) {
      resellerList();
    }

  }, []);

  function resellerList() {
    const Url = MAIN_URL+`?q=uusers/reseller_admin`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

  return (
    <>

         <div className="main-wrapper">
    <div className="profile">


    <div className="navbar two-action no-hairline">
    <div className="navbar-inner d-flex align-items-center justify-content-between">
      
    <SubHeader/>

    <div className="sliding custom-title">Re-Seller List</div>
    <div className="right mr-2">
    <Link to="/reseller/new" className="link icon-only">
    <i className="material-icons">add</i>
    </Link>
    </div>
    </div>
    </div>

    <div className="page-content mt-0">


    <ul id="tabs" className="nav nav-tabs" role="tablist">
    </ul>


    <div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>

    <div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Today">
    <div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
    <div className="card-box" style={{padding: "0px"}}>
    <div className="experience-box">
    <ul className="experience-list">



    {list.map(item => (
                        
                              
                    
                        <li className="col-12"  key={item.ID} style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                        <Link to={`/reseller/${item.ID}`}>
                        <div className="item-content d-flex pt-2 pb-2">
                        <div className="item-inner appraisal-col" style={{width: "100%"}}>
                        <div className="item-title-row d-flex align-items-center justify-content-between">
                        <div className="item-title"><strong>{item.NAME}</strong></div>
                        
                        </div>
                        <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                        <span className="leave-type">
                          {item.STATE}</span> 
      
                    
                          <span className="badge badge-danger float-right leave-status">{item.ID}</span>
                        
                        </div>
                        <div className="item-text leave-days">Phone : {item.PHONE}</div>
                        <div className="item-text leave-days">Email: {item.EMAIL}</div>
                        <div className="item-text leave-days">{item.ADDRESS}</div>
                        <div className="item-text leave-days">{item.CITY} || {item.ZIP}</div>
                        </div>
                        </div>
                        </Link>
                        </li>
                                            
                                              ))}
      
                              

    </ul>
    </div>
    </div>
    </div>
    </div>



    </div>


    </div>
    </div>
    </div>

      



    </>

  );
}

export default ResellerList;





