import { ActionTypes } from "../constants";


const intialState = {
  products: [],
};


const intialStateCart = {
  cartitems: [],
  cartitemcount: 0,
  isAddress: false,
  isCustomer: false,
};


const intialStateOrder = {
  orderItems: [],
  orderItemCount: 0,
};

const intialStateUsers = {
  isLogged: false,
  isSaller: false,
  isAdmin: false,
  isBmUser: false,
  user: [],
  seller: [],
};



export const productsReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: payload };
    default:
      return state;
  }
};

export const selectedProductsReducer = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SELECTED_PRODUCT:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_PRODUCT:
      return {};
    default:
      return state;
  }
};

export const addItemReducer = (state = intialStateCart, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_TO_CART:
      return { ...state, cartitems: payload };
      case ActionTypes.REMOVE_FROM_CART:
      return { ...state, cartitems: payload };
      case ActionTypes.LOAD_CART:
      return { ...state, cartitems: payload };
    default:
      return state;
  }
};


export const ItemAddOrder = (state = intialStateOrder, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_TO_ORDER:
      return { ...state, orderItems: payload };
      case ActionTypes.REMOVE_FROM_ORDER:
      return { ...state, orderItems: payload };
      case ActionTypes.LOAD_ORDER:
      return { ...state, orderItems: payload };
      case ActionTypes.ORDER_ADDRESS:
      return { ...state, isAddress: true }; 
      case ActionTypes.ORDER_CUSTOMER:
      return { ...state, isCustomer: true }; 
    default:
      return state;
  }
};


export const loadCart = (state = intialStateCart, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOAD_CART:
      return { ...state, cartitems: payload };
    default:
      return state;
  }
};


export const loggedReducer = (state = intialStateUsers, { payload, type }) => {
  switch (type) {
    case ActionTypes.SIGN_IN:
      return { ...state, isLogged: true };
      case ActionTypes.SIGN_OUT:
      return { ...state, isLogged: false };
      case ActionTypes.SELLER:
      return { ...state, isSaller: true }; 
      case ActionTypes.ADMIN:
      return { ...state, isAdmin: true }; 
      case ActionTypes.BIGMART_USER:
      return { ...state, isBmUser: true };
      case ActionTypes.USER_DATA:
      return { ...state, user: payload }; 
      default:
      return state;
  }
};


