import React from 'react';
import { useHistory } from "react-router-dom";

function SubHeader() {

  const history = useHistory();

  return (
    <>    

<div className="left mr-0">
<div  onClick={() => history.goBack()} className="back link d-flex align-items-center">
<span style={{color: "#fff"}}>Back</span>
</div>
</div>

    </>

  );
}

export default SubHeader;
