import React, { useEffect } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Main from './Global/Main'
import {useDispatch} from "react-redux";

function App() {
  
  const dispatch = useDispatch();

  
  useEffect(() => {
        const user      = localStorage.getItem("bm_user");
        const bm_seller = localStorage.getItem("bm_seller");


        if(user){
          const u = JSON.parse(user);
          if(parseInt(u.ACCESS)===5){dispatch({type: "ADMIN"});}
          dispatch({u, type: "USER_DATA"});
          dispatch({type: "SIGN_IN"});}
        if(bm_seller){
          dispatch({type: "SELLER"});
        }


  }, []);


  return (
    <Router>
      <Main/>
    </Router>
  );
}

export default App;
