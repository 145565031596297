
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MAIN_URL } from '../Global/Urls';
import axios from 'axios';
import SubHeader from '../Admin/comp/SubHeader';

function ActivityList() {
  
  const [lead, setLead] = useState([]);
  const [todayList, setTodayList] = useState([]);
  const [todayCount, setTodayCount] = useState(0);
  const [tommorrowList, setTommorrowList] = useState([]);
  const [tommorrowCount, setTommorrowCount] = useState(0);
  const [weekList, setweekList] = useState([]);
  const [weekCount, setweekCount] = useState(0);
  const [missed, setMissed] = useState([]);
  const [missedCount, setMissedCount] = useState(0);
  const [load, setLoad] = useState(false);




  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {
      today(parseInt(userData.ID));
      yesterday(parseInt(userData.ID));
      week(parseInt(userData.ID));
      mActivity(parseInt(userData.ID))
    }

  }, []);
  

  function today(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_list_open_preod/${ok}/t`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodayList(response.data.RS);
          setTodayCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }



   function yesterday(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_list_open_preod/${ok}/y`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTommorrowList(response.data.RS);
          setTommorrowCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

 
   function week(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_list_open_preod/${ok}/w`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setweekList(response.data.RS);
          setweekCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }
   
   function mActivity(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_list_open_preod/${ok}/m`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setMissed(response.data.RS);
          setMissedCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

  return (
    <>

         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">


<SubHeader/>


<div className="sliding custom-title">Activity</div>
<div className="right mr-2">
<a href="#" className="link icon-only" data-toggle="modal" data-target="#edit-profile">
<i className="far fa-edit"></i>
</a>
</div>
</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "25%"}}>
<a id="Today" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Today<br/>{todayCount}</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Tomorrow" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Tomorrow<br/>{tommorrowCount}</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Week" href="#pane-C" className="nav-link" data-toggle="tab" role="tab">Week<br/>{weekCount}</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Missed" href="#pane-D" className="nav-link" data-toggle="tab" role="tab">Missed<br/>{missedCount}</a>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>

<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Today">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">




{todayList.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/activity/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-media mr-2" style={{marginTop: "18px"}}><img width="36" className="rounded-circle" src={`/assets/img/${item.TYPE.replace(" ", "_")}.jpg`} alt=""/></div>
                    <div className="item-inner appraisal-col">
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.COMPANY_NAME}<br/>{item.LEAD_NAME}</strong></div>
                    {item.STATUS==0 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed/Open</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                      <span className="leave-type">{item.SUBJECT===null || item.SUBJECT==="" ? item.TYPE : item.SUBJECT}</span> 
                      {item.START_TIME>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">Start Time: {(new Date(item.START_TIME*1000)).toLocaleString()}</div>
                    <div className="item-text leave-days">For : {item.OWNER_NAME}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}
</ul>
</div>
</div>
</div>
</div>




<div id="pane-B" className="tab-pane fade" role="tabpanel" aria-labelledby="Tomorrow">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{tommorrowList.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/activity/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-media mr-2" style={{marginTop: "18px"}}><img width="36" className="rounded-circle" src={`/assets/img/${item.TYPE.replace(" ", "_")}.jpg`} alt=""/></div>
                    <div className="item-inner appraisal-col">
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.COMPANY_NAME}<br/>{item.LEAD_NAME}</strong></div>
                    {item.START_TIME>Date.now()/1000 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed/Open</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">{item.SUBJECT===null || item.SUBJECT==="" ? item.TYPE : item.SUBJECT}</span> 
                      {item.START_TIME>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">Start Time: {(new Date(item.START_TIME*1000)).toLocaleString()}</div>
                    <div className="item-text leave-days">For : {item.OWNER_NAME}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}
</ul>
</div>
</div>
</div>
</div>


<div id="pane-C" className="tab-pane fade" role="tabpanel" aria-labelledby="Tomorrow">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{weekList.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/activity/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-media mr-2" style={{marginTop: "18px"}}><img width="36" className="rounded-circle" src={`/assets/img/${item.TYPE.replace(" ", "_")}.jpg`} alt=""/></div>
                    <div className="item-inner appraisal-col">
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.COMPANY_NAME}<br/>{item.LEAD_NAME}</strong></div>
                    {item.START_TIME>Date.now()/1000 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed/Open</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">{item.SUBJECT===null || item.SUBJECT==="" ? item.TYPE : item.SUBJECT}</span> 
                      {item.START_TIME>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">Start Time: {(new Date(item.START_TIME*1000)).toLocaleString()}</div>
                    <div className="item-text leave-days">For : {item.OWNER_NAME}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}
</ul>
</div>
</div>
</div>
</div>



<div id="pane-D" className="tab-pane fade" role="tabpanel" aria-labelledby="Missed">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{missed.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/activity/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-media mr-2" style={{marginTop: "18px"}}><img width="36" className="rounded-circle" src={`/assets/img/${item.TYPE.replace(" ", "_")}.jpg`} alt=""/></div>
                    <div className="item-inner appraisal-col">
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.COMPANY_NAME}<br/>{item.LEAD_NAME}</strong></div>
                    {item.START_TIME>Date.now()/1000 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed/Open</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">{item.SUBJECT===null || item.SUBJECT==="" ? item.TYPE : item.SUBJECT}</span> 
                      {item.START_TIME>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">Start Time: {(new Date(item.START_TIME*1000)).toLocaleString()}</div>
                    <div className="item-text leave-days">For : {item.OWNER_NAME}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}
</ul>
</div>
</div>
</div>
</div>


</div>


</div>
</div>
</div>

   



    </>

  );
}

export default ActivityList;





