import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { Link, useHistory } from "react-router-dom";
import SubHeader from './comp/SubHeader';

function UserList() {
  

  const history = useHistory();
  const [userList, setuserList] = useState([]);
   
   useEffect(() => {
     const userDetails = localStorage.getItem("user");

     if(userDetails){
       const userData = JSON.parse(userDetails);  

  
    if (parseInt(userData.ID)>0) {

      if(userData.ACCESS===3){getUserListP(parseInt(userData.PARTNER_ID));}
      else{getUserList();}
      }
     else{history.push("/user/login");}


  }
    
   }, []);
 
 
 
   function getUserList() {
    const Url = MAIN_URL+`?q=uusers/users_list_admin`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setuserList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   
   
   function getUserListP(id) {
    const Url = MAIN_URL+`?q=uusers/users_list_partner/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setuserList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   

   const loadUser = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    history.push("/");
  }




  return (
    <>
    
    <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">
  
<SubHeader/>

<div className="sliding custom-title">User List</div>
<div className="right mr-2">
<Link to="/user/new" className="link icon-only">
<i className="material-icons">add</i>
</Link>
</div>
</div>
</div>

<div className="page-content mt-0">


<div className="page-content">
<div className="dashboard-area">


<div className="card-box" style={{padding: "0px", marginTop:"-15px"}}>
  
<div className="experience-box">
<ul className="experience-list">

{userList.map(item => (
                 
                 <li className="col-12"  key={item.ID} style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                 <Link to={`/reseller/${item.ID}`}>
                 <div className="item-content d-flex pt-2 pb-2">
                 <div className="item-inner appraisal-col" style={{width: "100%"}}>
                 <div className="item-title-row d-flex align-items-center justify-content-between">
                 <div className="item-title"><strong>{item.NAME}</strong></div>
                 
                 </div>
                 <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                 <span className="leave-type">
                   {item.STATE}</span> 

             
                   <span className="badge badge-danger float-right leave-status">{item.ID}</span>
                 
                 </div>
                 <div className="item-text leave-days">Phone : {item.PHONE}</div>
                 <div className="item-text leave-days">Email: {item.EMAIL}</div>
                 <div className="item-text leave-days">{item.ADDRESS}</div>
                 <div className="item-text leave-days">{item.CITY} || {item.ZIP}</div>
                 </div>
                 </div>
                 </Link>
                 </li>
                    
                                        
                                        ))}
</ul>
</div>
</div>




</div>
</div>
</div>

</div>
</div>

    </>

  );
}

export default UserList;
