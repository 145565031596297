
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { Link, useParams } from 'react-router-dom';
import SubHeader from '../Admin/comp/SubHeader';

function CompanyDetails() {


  const { id } = useParams();
  
  const [lead, setLead] = useState([]);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState([]);

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);
  
    if (parseInt(userData.ID)>0) {
      setUser(userData);
      getTicketMaster();
        }

  }, []);
  



   
  function getTicketMaster() {
      const Url = MAIN_URL+`?q=ucompany/d/${id}`;
      axios.get(Url)
      .then(response => {
          if(response.data.SUCCESS > 0){
            setLead(response.data.RS);

            
            setLoad(true);
          }
      });

   }



  return (
    <>

    {load? (
      <>
          <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">Company Details</div>




</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "100%"}}>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>
<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Overview">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="list">


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>{lead.COMPANY_NAME}</strong>
  
  </p>
</div>
</div>


<div className="card-box" style={{marginTop: "-15px"}}>
<ul>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Phone</div>
<div className="item-after text-muted">{lead.PHONE_NO}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Email</div>
<div className="item-after text-muted">{lead.EMAIL}</div>
</div>
</div>
</li>




</ul>
</div>

<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Company Details</strong></p>
</div>
</div>



<div className="card-box" style={{marginTop: "-15px"}}>
<ul>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">City</div>
<div className="item-after text-muted">{lead.CITY}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Address</div>
<div className="item-after text-muted">{lead.ADDRESS}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">City</div>
<div className="item-after text-muted">{lead.CITY}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">State</div>
<div className="item-after text-muted">{lead.STATE}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Zip</div>
<div className="item-after text-muted">{lead.ZIP}</div>
</div>
</div>
</li>




</ul>
</div>



<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Other Details</strong></p>
</div>
</div>



<div className="card-box" style={{marginTop: "-15px"}}>
<ul>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Installed On</div>
<div className="item-after text-muted">{(new Date(lead.REGISTER_ON/1)).toLocaleString()}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Valid UpTo</div>
<div className="item-after text-muted">{(new Date(lead.VALID_TILL/1)).toLocaleString()}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Is Licensed</div>
<div className="item-after text-muted">{lead.IS_LICENCED}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Last BackUp</div>
<div className="item-after text-muted">{(new Date(lead.LAST_BACKUP/1)).toLocaleString()}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Last Active</div>
<div className="item-after text-muted">{(new Date(lead.LAST_ACTIVE/1)).toLocaleString()}</div>
</div>
</div>
</li>




</ul>
</div>



</div>
</div>
</div>

</div>


</div>
</div>
</div>
      </>
    ) : (
      <></>
      )}
   



    </>

  );
}

export default CompanyDetails;





