
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { Link, useHistory } from "react-router-dom";
import SubHeader from '../../Admin/comp/SubHeader';

function DealList() {


   const [orderList, setOrderList] = useState([]);
   const [user, setUser] = useState([]);
   const [loded, setLoded] = useState(false);
   const [page, setPage] = useState(1);
   const [pagging, setPagging] = useState(true);
 

   useEffect(() => {

    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const udata = JSON.parse(loggedInUser);
      setUser(udata);
      getList(udata.ID);
    }
     
     }, []);

       
   function getList(sid) {
    const Url = MAIN_URL+`?q=uleads/deal_list/${sid}/${page}`;
    axios.get(Url)
    .then(response => {
        console.log(response.data.RS);
        setOrderList(response.data.RS);
        setLoded(true);
        setPage(page+1);
    })
    .catch(error => {
        console.log(error);
    });

   }

   const LoadMore = () => {
   
    const Url = MAIN_URL+`?q=uleads/deal_list/${user.ID}/${page}`;
    axios.get(Url)
    .then(response => {
        console.log(response.data);
        console.log(orderList);
        orderList.push(...response.data.RS);
        setOrderList(orderList);
        setLoded(true);
        setPage(page+1);
        
    })
    .catch(error => {
        console.log(error);
    });

}


     
 

  return (
    <>
   

<div className="performance-appraisal">
<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">
  
<SubHeader/>

<div className="sliding custom-title">Deal List</div>
<div className="right">
<Link to="/lead/new" className="link icon-only">
<i className="material-icons">add</i>
</Link>
</div>
</div>
</div>

<div className="page-content" style={{marginTop: "-15px"}}>
<div className="list no-hairlines media-list salary-list">
<ul>

{loded? (
<>
{orderList.map(item => (
                    
                    

                 
  <li className="col-12" style={{marginTop: "-0px"}}>
  <Link to={`/lead/details/${item.ID}`}>
  <div className="item-content d-flex pt-2 pb-2">
  <div className="item-media mr-2" style={{marginTop: "12px"}}><img width="36" className="rounded-circle" src="/assets/img/user.jpg" alt=""/></div>
  <div className="item-inner appraisal-col">
  <div className="item-title-row d-flex align-items-center justify-content-between">
  <div className="item-title"><strong>{item.LEAD_NAME}</strong></div>
  <div className="item-after">{(new Date(item.DEAL_CLOSING_DATE*1000)).toLocaleString()}</div>
  
  </div>

  <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0"><span className="leave-type">{item.COMPANY_NAME}</span> 
  
    {item.DEAL_CLOSING_DATE>Date.now()/1000 ? (
    <span className="badge badge-success float-right leave-status">{item.DEAL_MAX_AMOUNT}</span>
    ) : (
    <span className="badge badge-danger float-right leave-status">{item.DEAL_MAX_AMOUNT}</span>
    )}
  </div>
  <div className="item-text leave-days">{item.INDUSTRY}</div>
  </div>
  </div>
  </Link>
  </li>
  
                      
                      ))}
                      </>

) : (

  <>
  </>
)}
  


  {pagging? (

<li className="col-12" style={{padding: "15px", marginTop: "-5px"}}>
<button className="button button-big button-purple" onClick={LoadMore} type="button">Load More</button>
  </li>
               ) : (
  <li className="col-12" style={{marginTop: "-0px"}}>
  <button className="button button-big button-purple" type="button">No More Lead</button>
  </li>
               )}
       



</ul>
</div>
</div>
</div>
    </>

  );
}

export default DealList;





