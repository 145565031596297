import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { Link, useHistory } from "react-router-dom";
import GuestHeader from '../Global/Header';

function AdminHome() {


   const [productlist, setProductList] = useState([]);
   const history = useHistory();
   const [balance, setBalance] = useState("");
   const [admin, setAdmin] = useState(false);

   useEffect(() => {
     const userDetails = localStorage.getItem("user");

     if(userDetails){
       const sData = JSON.parse(userDetails);  

       console.log(sData);

       if(parseInt(sData.ACCESS)===5 || parseInt(sData.ACCESS)===3){

        if(parseInt(sData.ACCESS)===5){setAdmin(true);}
        product_list();
        SmsBallance();

       }

       else{history.push("");}
      }
     else{history.push("");}
   }, []);
 
 
 

    
 
 
   function SmsBallance() {
    const Url = `http://neat.freebeesms.com/api/balance.php?authkey=8bdd8afd16cf2870eaf32f634193f9cb&route=B`;
    axios.get(Url)
    .then(response => {
      setBalance(parseInt(response.data))
    })
    .catch(error => {
        console.log(error);
    });

   }

 
   function product_list() {
    const Url = MAIN_URL+`?q=product/product_list/1`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setProductList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }
   
   
   
  function logoutUsers(){
    localStorage.removeItem("bm_user");
    localStorage.removeItem("user");
    history.go(0);
  }


  return (
    <>
    <div id="main" class="main-wrapper">

<GuestHeader/>



<div className="home">



<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">
<div className="left">
<a href="#" className="link icon-only"><img src="/assets/img/logo.png" alt="" className=""/></a>
</div>
<div className="right d-flex">
<a href="#" data-toggle="dropdown" aria-expanded="true" className="link dropdown-link"><i className="material-icons">more_vert</i></a>
<div className="dropdown-menu dropdown-menu-right header_drop_icon">
<Link to="/user/profile" className="dropdown-item">My Profile</Link>
<a onClick={()=> logoutUsers()} className="dropdown-item">Log Out</a>
</div>
</div>
</div>
</div>

<div className="page-content" style={{marginTop:"0px"}}>
<div className="container" style={{padding: '0px'}}>

<div className="dashboard-area">





<div className="row" id="tt">
<div className="col-12">
<div className="chart-box" style={{textAlign: 'left'}}>
<h3 className="title is-3"> {admin ? "Admin Home" : " Partner Admin Home"}</h3>

<div class="list links-list settings-list">
<ul>
<li style={{marginLeft: "-15px"}}><Link to="/admin/lead"><b>New Lead Report</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/admin/activity"><b>Activity Creation Report</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/admin/deal"><b>Pending Deal Report</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/admin/company-convert"><b>Lead Convert Report</b></Link></li>
<li style={{marginLeft: "-15px"}}><Link to="/admin/user-list"><b>User List</b></Link></li>
{admin ? (
  <>
  <li style={{marginLeft: "-15px"}}><Link to="/admin/ticket-closed"><b>Ticket Closed Report</b></Link></li>
  <li style={{marginLeft: "-15px"}}><Link to="/admin/reseller-list"><b>Reseller List</b></Link></li>
  <li style={{marginLeft: "-15px"}}><Link to="/admin/company"><b>Installed Company Report</b></Link></li>
  <li style={{marginLeft: "-15px"}}><Link to="/admin/company-list-expiry"><b>Company List [Validity]</b></Link></li>
  <li style={{marginLeft: "-15px"}}><Link to="/admin/company-list"><b>Installed Company List</b></Link></li>
  </>
) : (
  <>
  
  </>
)}

<li style={{marginLeft: "-15px"}}><Link to=""><b>Goto Home</b></Link></li>

</ul>
</div>

</div>

</div>

</div>


{admin? (
<>
<div className="row" id="tt">
<div className="col-12">
<div className="chart-box" style={{textAlign: 'left'}}>
<h3 className="title is-3">Product List | {balance}</h3>

<div class="list links-list settings-list">
<ul>
{productlist.map(item => (

<li style={{marginLeft: "-15px"}}><Link to={`/admin/product_details/${item.ID}`}><b>{item.PRODUCT_NAME}</b> 

</Link></li>
                                        ))}

</ul>
</div>

</div>

</div>

</div>
</>
) : (
<>
</>
)}







</div>
</div>
</div>

</div>
</div>

    </>

  );
}

export default AdminHome;
