import { combineReducers } from 'redux';
import { productsReducer, addItemReducer, loggedReducer, ItemAddOrder} from "./StoreItemReducer";


export default combineReducers({
    allProducts: productsReducer,
    cartItem : addItemReducer, loggedReducer,
    order : ItemAddOrder
    
}); 

