
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { Link, useParams } from 'react-router-dom';
import SubHeader from '../../Admin/comp/SubHeader';

function AcitvityDetails() {


  const { id } = useParams();
  
  const [lead, setLead] = useState([]);
  const [openList, setOpenList] = useState([]);
  const [closeList, setCloseList] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {
          getOrderMaster();
        }

  }, []);
  



  
  function getOpenList(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_list_lead_open/${ok}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setOpenList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }


  
   function getCloseList(ok) {
    const Url = MAIN_URL+`?q=uactivity/activity_list_lead_closed/${ok}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setCloseList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   
  function getOrderMaster() {
      const Url = MAIN_URL+`?q=uactivity/d/${id}`;
      axios.get(Url)
      .then(response => {
          if(response.data.SUCCESS > 0){
            setLead(response.data.RS);
            getOpenList(response.data.RS.LEAD_ID);
            getCloseList(response.data.RS.LEAD_ID);
            setLoad(true);
          }
      });

   }



 

  return (
    <>

    {load? (
      <>
         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>


<div className="sliding custom-title">Activity Details [{lead.ID}]</div>
<div className="right mr-2">
<Link to={`/activity/closed/${id}`} className="link icon-only">
<i className="far fa-edit"></i>
</Link>
</div>
</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "100%"}}>
<a id="Overview" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Details</a>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>
<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Overview">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="list">


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Contact Info</strong></p>
</div>
</div>


<div className="card-box" style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Phone</div>
<div className="item-after text-muted">{lead.PHONE}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Email</div>
<div className="item-after text-muted">{lead.EMAIL}</div>
</div>
</div>
</li>


</ul>
</div>


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Activity Info</strong></p>
</div>
</div>
<div className="card-box"  style={{marginTop: "-15px"}}>
<ul>
  

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Assign To</div>
<div className="item-after text-muted">{lead.OWNER_NAME}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Activity On</div>
<div className="item-after text-muted">{(new Date(lead.START_TIME*1000)).toLocaleString()}</div>
</div>
</div>
</li>



<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Activity Type</div>
<div className="item-after text-muted">{lead.TYPE}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Subject :</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-after text-muted">{lead.SUBJECT}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Close Details :</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">{lead.DESCRIPTION}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Create By</div>
<div className="item-after text-muted">{lead.CREATOR_NAME}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Create On</div>
<div className="item-after text-muted">{(new Date(lead.CREATE_ON*1000)).toLocaleString()}</div>
</div>
</div>
</li>

</ul>
</div>

<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Company Details</strong></p>
</div>
</div>



<div className="card-box" style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Company</div>
<div className="item-after text-muted">{lead.COMPANY_NAME}</div>
</div>
</div>
</li>


<li>





<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">City</div>
<div className="item-after text-muted">{lead.CITY}</div>

</div>
</div>


</li>
</ul>
</div>

</div>
</div>
</div>


</div>

</div>
</div>
</div>
      </>
    ) : (
      <></>
      )}
   



    </>

  );
}

export default AcitvityDetails;





