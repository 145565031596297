import React, { useState, useEffect } from 'react';
import SideBar from './SideBar';

function Header() {

  
  
  const [user, setUser] = useState([]);
    
  useEffect(() => {
    
    const loggedInUser = localStorage.getItem("bm_user");
    
    if (loggedInUser) {
      setUser(JSON.parse(loggedInUser));
    }



  }, []);
  
  return (
    <>
      <div className="side-menu" id="sidebar-menu">
<div className="close-btn"><span className="material-icons">close</span></div>
<div className="side-menu" id="sidebar-menu">
<ul>
<li>
<a className="panel-close" href="index.html">
Dashboard
</a>
</li>
<li className="submenu">
<a href="#"><span>Apps</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="chat.html"> Chat</a></li>
<li className="submenu">
<a href="#"><span>Calls</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="voice-call.html"> Voice Call </a></li>
<li><a className="panel-close" href="video-call.html"> Video Call </a></li>
<li><a className="panel-close" href="incoming-call.html"> Incoming Call </a></li>
</ul>
</li>
<li><a className="panel-close" href="contacts.html">Contacts</a></li>
<li><a className="panel-close" href="email.html">Email</a></li>
<li><a className="panel-close" href="calendar.html">Calendar</a></li>
</ul>
</li>
<li className="submenu">
<a href="#"><span>Employees</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="employees.html"> All Employees</a></li>
<li><a className="panel-close" href="attendance.html"> Attendance</a></li>
<li><a className="panel-close" href="attendance-list.html"> Attendance List</a></li>
<li><a className="panel-close" href="holidays.html"> Holidays </a></li>
<li><a className="panel-close" href="leaves.html"> Leave Requests </a></li>
<li><a className="panel-close" href="departments.html"> Departments </a></li>
<li><a className="panel-close" href="designations.html"> Designations </a></li>
</ul>
</li>
<li>
<a className="panel-close" href="clients.html">
Clients
</a>
</li>
<li className="submenu">
<a href="#"><span>Projects</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="projects.html"> Projects</a></li>
<li><a className="panel-close" href="tasks.html"> Tasks</a></li>
</ul>
</li>
<li>
<a className="panel-close" href="calendar-page.html">
Events
</a>
</li>
<li className="submenu">
<a href="#"><span>Accounts</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="estimates.html"> Estimates</a></li>
<li><a className="panel-close" href="invoices.html"> Invoices </a></li>
<li><a className="panel-close" href="payments.html"> Payments </a></li>
<li><a className="panel-close" href="taxes.html"> Taxes </a></li>
</ul>
</li>
<li className="submenu">
<a href="#"><span>Payroll</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="salary.html"> Employee Salary </a></li>
<li><a className="panel-close" href="salary-view.html"> Payslip </a></li>
</ul>
</li>
<li>
<a className="panel-close" href="users.html">
Users
</a>
</li>
<li>
<a className="panel-close" href="settings.html">
Settings
</a>
</li>
<li>
<a className="panel-close" href="assets.html">
Assets
</a>
</li>
<li>
<a className="panel-close" href="policies.html">
Policies
</a>
</li>
<li className="submenu">
<a href="#"><span>Performance</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="performance-indicator.html"> Performance Indicator</a></li>
<li><a className="panel-close" href="performance-appraisal.html"> Performance Appraisal</a></li>
</ul>
</li>
<li className="submenu">
<a href="#"><span>Pages</span> <span className="menu-arrow"></span></a>
<ul style={{display: "none"}}>
<li><a className="panel-close" href="login.html"> Login </a></li>
<li><a className="panel-close" href="register.html"> Register </a></li>
<li><a className="panel-close" href="forgot-password.html"> Forgot Password </a></li>
<li><a className="panel-close" href="profile.html"> Profile </a></li>
<li><a className="panel-close" href="walkthrough.html"> Walkthrough </a></li>
</ul>
</li>
</ul>
</div>
</div>

    </>

  );
}

export default Header;
