
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { Link, useParams } from 'react-router-dom';
import SubHeader from '../../Admin/comp/SubHeader';

function TicketDetails() {


  const { id } = useParams();
  
  const [lead, setLead] = useState([]);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState([]);
  const [creator, setCreator] = useState([]);
  const [assignor, setAssignor] = useState([]);

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);
  
    if (parseInt(userData.ID)>0) {
      setUser(userData);
      getTicketMaster();
        }

  }, []);
  



   
  function getTicketMaster() {
      const Url = MAIN_URL+`?q=uticket/d/${id}`;
      axios.get(Url)
      .then(response => {
          if(response.data.SUCCESS > 0){
            setLead(response.data.RS);
            getCreator(response.data.RS.CREATE_BY);
            getAssignor(response.data.RS.ASSIGNED_BY);

            
            setLoad(true);
          }
      });

   }

   
  function getCreator(cid) {
    const Url = MAIN_URL+`?q=uusers/d/${cid}`;
    axios.get(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setCreator(response.data.RS);
        }
    });

 }

  
 function getAssignor(cid) {
  const Url = MAIN_URL+`?q=uusers/d/${cid}`;
  axios.get(Url)
  .then(response => {
      if(response.data.SUCCESS > 0){
        setAssignor(response.data.RS);
      }
  });

}


  return (
    <>

    {load? (
      <>
          <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">Ticket Details</div>

{parseInt(lead.ASSIGNED_TO)===parseInt(user.ID) || parseInt(lead.CREATE_BY)===parseInt(user.ID) && parseInt(lead.STATUS)===0 ? (
  <>
    <div className="right mr-2">
    <Link to={`/ticket/closed/${lead.ID}`} className="link icon-only">
    <i class="fas fa-store-alt-slash"></i>
    </Link>
    </div>
  </>
) : (
  <>
  </>
)}



</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "100%"}}>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>
<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Overview">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="list">


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Contact Info <span className="pull-right" style={{color: "GrayText"}}>{parseInt(lead.STATUS)===1?"Closed":"Open"}</span></strong></p>
</div>
</div>


<div className="card-box" style={{marginTop: "-15px"}}>
<ul>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Name</div>
<div className="item-after text-muted">{lead.CNAME}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Phone</div>
<div className="item-after text-muted">{lead.CPHONE}</div>
</div>
</div>
</li>

{lead.EMAIL? (
  <>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Email</div>
<div className="item-after text-muted">{lead.EMAIL}</div>
</div>
</div>
</li>

  </>
) : (
  <>
  </>
)}


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Designation</div>
<div className="item-after text-muted">{lead.DESIGNATION}</div>
</div>
</div>
</li>

</ul>
</div>

<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Company Details</strong></p>
</div>
</div>



<div className="card-box" style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Company</div>
<div className="item-after text-muted">{lead.COMPANY_NAME}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Name</div>
<div className="item-after text-muted">{lead.NAME}</div>
</div>
</div>
</li>
<li>





<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">City</div>
<div className="item-after text-muted">{lead.CITY}</div>

</div>
</div>


</li>
</ul>
</div>



<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Ticket Info</strong></p>
</div>
</div>
<div className="card-box"  style={{marginTop: "-15px"}}>
<ul>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Assign By</div>
<div className="item-after text-muted">{assignor.NAME}</div>
</div>
</div>
</li>  

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Assign To</div>
<div className="item-after text-muted">{lead.OWNER_NAME}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Remind On</div>
<div className="item-after text-muted">{(new Date(lead.REMIND_ON*1000)).toLocaleString()}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Scheduled On</div>
<div className="item-after text-muted">{(new Date(lead.SCHEDULE_DATE*1000)).toLocaleString()}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Subject :</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">{lead.SUBJECT}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Details :</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">{lead.DESCRIPTION}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Create By</div>
<div className="item-after text-muted">{creator.NAME}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Create On</div>
<div className="item-after text-muted">{(new Date(lead.CREATE_ON*1000)).toLocaleString()}</div>
</div>
</div>
</li>

</ul>
</div>


</div>
</div>
</div>

</div>


</div>
</div>
</div>
      </>
    ) : (
      <></>
      )}
   



    </>

  );
}

export default TicketDetails;





