
import React, { useState, useEffect } from 'react';
import { MAIN_URL } from '../Global/Urls';
import axios from 'axios';
import SubHeader from './comp/SubHeader';
import { Link, useParams } from 'react-router-dom';

function LeadListUser() {
  
  const { id } = useParams();

  
  const [lead, setLead] = useState([]);
  const [todayList, setTodayList] = useState([]);
  const [todayCount, setTodayCount] = useState(0);
  const [tommorrowList, setTommorrowList] = useState([]);
  const [tommorrowCount, setTommorrowCount] = useState(0);
  const [weekList, setweekList] = useState([]);
  const [weekCount, setweekCount] = useState(0);
  const [missed, setMissed] = useState([]);
  const [missedCount, setMissedCount] = useState(0);
  const [load, setLoad] = useState(false);




  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {

     
        today();
        yesterday();
        week();
        mActivity();
   
    }

  }, []);
  

  function today() {
    const Url = MAIN_URL+`?q=uleads/lead_list_duration/t/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTodayList(response.data.RS);
          setTodayCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }



   function yesterday() {
    const Url = MAIN_URL+`?q=uleads/lead_list_duration/y/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setTommorrowList(response.data.RS);
          setTommorrowCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

 
   function week() {
    const Url = MAIN_URL+`?q=uleads/lead_list_duration/w/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setweekList(response.data.RS);
          setweekCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }
   
   function mActivity() {
    const Url = MAIN_URL+`?q=uleads/lead_list_duration/m/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setMissed(response.data.RS);
          setMissedCount(response.data.RS.length);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   

  return (
    <>

         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">Lead Creation Details</div>
</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "25%"}}>
<a id="Today" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Today
<br/>
{todayCount}
</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Tomorrow" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Yesterday
<br/>
{tommorrowCount}
</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Week" href="#pane-C" className="nav-link" data-toggle="tab" role="tab">Week
<br/>
{weekCount}
</a>
</li>
<li className="nav-item" style={{width: "25%"}}>
<a id="Missed" href="#pane-D" className="nav-link" data-toggle="tab" role="tab">Month
<br/>
{missedCount}
</a>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>

<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Today">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">


{todayList.map(item => (
                 

                 
  <li className="col-12 salary-list" style={{marginTop: "-0px"}}>
  <Link to={`/lead/details/${item.ID}`}>
  <div className="item-content d-flex pt-2 pb-2">
  <div className="item-inner appraisal-col"  style={{width: "100%"}}>
  <div className="item-title-row d-flex align-items-center justify-content-between">
  <div className="item-title"><strong>{item.LEAD_NAME}</strong></div>
  <div className="item-after">{item.LEAD_SOURCE}
  </div>
  
  </div>
  <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0"><span className="leave-type">{item.COMPANY_NAME}</span> 
  <span className="badge badge-success float-right leave-status">{item.PRIORITY}</span></div>
  

  {item.PHONE && 
            <div className="item-text leave-days">Phone: {item.PHONE}</div>
          }
  {item.CITY && 
            <div className="item-text leave-days">City: {item.CITY}</div>
          }
  {item.INDUSTRY && 
            <div className="item-text leave-days">Type: {item.INDUSTRY}</div>
          }

  </div>
  </div>
  </Link>
  </li>
  
                                        
                                        ))}

                                        


</ul>
</div>
</div>
</div>
</div>




<div id="pane-B" className="tab-pane fade" role="tabpanel" aria-labelledby="Tomorrow">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{tommorrowList.map(item => (
                    
                     

                 
  <li className="col-12 salary-list" style={{marginTop: "-0px"}}>
  <Link to={`/lead/details/${item.ID}`}>
  <div className="item-content d-flex pt-2 pb-2">
  <div className="item-inner appraisal-col"  style={{width: "100%"}}>
  <div className="item-title-row d-flex align-items-center justify-content-between">
  <div className="item-title"><strong>{item.LEAD_NAME}</strong></div>
  <div className="item-after">{item.LEAD_SOURCE}
  </div>
  
  </div>
  <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0"><span className="leave-type">{item.COMPANY_NAME}</span> 
  <span className="badge badge-success float-right leave-status">{item.PRIORITY}</span></div>



  {item.PHONE && 
            <div className="item-text leave-days">Phone: {item.PHONE}</div>
          }
  {item.CITY && 
            <div className="item-text leave-days">City: {item.CITY}</div>
          }
  {item.INDUSTRY && 
            <div className="item-text leave-days">Type: {item.INDUSTRY}</div>
          }



  </div>
  </div>
  </Link>
  </li>
  
                                        
                                        ))}

                                        

</ul>
</div>
</div>
</div>
</div>


<div id="pane-C" className="tab-pane fade" role="tabpanel" aria-labelledby="Tomorrow">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{weekList.map(item => (
                    
                    

                 
  <li className="col-12 salary-list" style={{marginTop: "-0px"}}>
  <Link to={`/lead/details/${item.ID}`}>
  <div className="item-content d-flex pt-2 pb-2">
  <div className="item-inner appraisal-col"  style={{width: "100%"}}>
  <div className="item-title-row d-flex align-items-center justify-content-between">
  <div className="item-title"><strong>{item.LEAD_NAME}</strong></div>
  <div className="item-after">{item.LEAD_SOURCE}
  </div>
  
  </div>
  <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0"><span className="leave-type">{item.COMPANY_NAME}</span> 
  <span className="badge badge-success float-right leave-status">{item.PRIORITY}</span></div>




  {item.PHONE && 
            <div className="item-text leave-days">Phone: {item.PHONE}</div>
          }
  {item.CITY && 
            <div className="item-text leave-days">City: {item.CITY}</div>
          }
  {item.INDUSTRY && 
            <div className="item-text leave-days">Type: {item.INDUSTRY}</div>
          }


  </div>
  </div>
  </Link>
  </li>
               
                                        ))}

                                        
</ul>
</div>
</div>
</div>
</div>



<div id="pane-D" className="tab-pane fade" role="tabpanel" aria-labelledby="Missed">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{missed.map(item => (
                  

                 
  <li className="col-12 salary-list" style={{marginTop: "-0px"}}>
  <Link to={`/lead/details/${item.ID}`}>
  <div className="item-content d-flex pt-2 pb-2">
  <div className="item-inner appraisal-col"  style={{width: "100%"}}>
  <div className="item-title-row d-flex align-items-center justify-content-between">
  <div className="item-title"><strong>{item.LEAD_NAME}</strong></div>
  <div className="item-after">{item.LEAD_SOURCE}
  </div>
  
  </div>
  <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0"><span className="leave-type">{item.COMPANY_NAME}</span> 
  <span className="badge badge-success float-right leave-status">{item.PRIORITY}</span></div>



  {item.PHONE && 
            <div className="item-text leave-days">Phone: {item.PHONE}</div>
          }
  {item.CITY && 
            <div className="item-text leave-days">City: {item.CITY}</div>
          }
  {item.INDUSTRY && 
            <div className="item-text leave-days">Type: {item.INDUSTRY}</div>
          }




  </div>
  </div>
  </Link>
  </li>
  
                                        
                                        ))}


</ul>
</div>
</div>
</div>
</div>


</div>


</div>
</div>
</div>

   



    </>

  );
}

export default LeadListUser;





