
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { Link, useParams, useHistory } from 'react-router-dom';
import SubHeader from './comp/SubHeader';

function ProductVersoningEntry() {


  const { id } = useParams();
  const history = useHistory();

  const [product, setProduct] = useState([]);
  const [module, setModule] = useState("");
  const [note, setNote] = useState("");
  const [verson, setVerson] = useState(`${new Date().getFullYear()}${new Date().getMonth()+1}${new Date().getDate()}`);
  const [load, setLoad] = useState(false);
  const [text, setText] = useState("Save");

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {
          getOrderMaster();
        }

  }, []);
  




   
  function getOrderMaster() {
      const Url = MAIN_URL+`?q=product/${id}`;
      axios.get(Url)
      .then(response => {
          if(response.data.SUCCESS > 0){
            setProduct(response.data.RS);
            setLoad(true);
          }
      });

   }

    
                 
  const CreateItem = (e) => {
    e.preventDefault();
    const recipeUrl = MAIN_URL+'?url=db_in/save/SOFTWARE_VERSIONS';
    let formData = new FormData();    //formdata object

    formData.append('VERSION', verson);
    formData.append('MODULE_NAME', module);
    formData.append('NOTE', note);
    formData.append('CREATE_ON', Date.now());
    formData.append('SOFTWARE_ID', id);


      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Date Saved");
              history.push(`/admin/product_details/${product.ID}`);
            }
        })
        .catch(error => {
            console.log(error);
    });

  }





 

  return (
    <>

    {load? (
      <>



<div className="main-wrapper">
  <div className="add-client">

  <div className="navbar two-action no-hairline">
  <div className="navbar-inner d-flex align-items-center justify-content-between">
  <SubHeader/>
  <div className="sliding custom-title">Add Updates</div>
  <div className="right">
  </div>
  </div>
  </div>

  <div className="page-content" style={{marginTop: "-16px"}}>
  <div className="list no-hairlines custom-form">
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Version No</div>
  <div className="item-input-wrap">
  <input onChange={e => setVerson(e.target.value)} value={verson} type="text"/>
  </div>
  </div>
  </li>




  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Module Name</div>
  <div className="item-input-wrap">
  <input onChange={e => setModule(e.target.value)} value={module} type="text"/>
  </div>
  </div>
  </li>



  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Note</div>
  <div className="item-input-wrap">
  <textarea type="text" onChange={e => setNote(e.target.value)} value={note}/>
  </div>
  </div>
  </li>


  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">
  <button className="button button-big button-purple" onClick={CreateItem} type="button">{text}</button>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>




  </div>
  </div>


      </>
    ) : (
      <></>
      )}
   



    </>

  );
}

export default ProductVersoningEntry;





