
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { Link, useHistory } from "react-router-dom";
import SubHeader from '../../Admin/comp/SubHeader';

function NewLead() {

  const history = useHistory();


  const [user, setUser] = useState([]);
  const [text, setText] = useState("Save");
  const [leadName, setLeadName] = useState("");
  const [dasingation, setDasingation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [sorce, setSorce] = useState("");
  const [indusury, setIndusury] = useState("");
  const [indusuryNote, setIndusuryNote] = useState("");
  const [company, setCompany] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("India");
  const [description, setDescription] = useState("");
  const [currentSoftware, setCurrentSoftware] = useState("");
  const [reasone, setReasone] = useState("");
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState("");

  
  const onChangeDesignation = (e) => {
    const type = e.target.value;
    setDasingation(type);
  };

     
  const onChangeSorce = (e) => {
    const type = e.target.value;
    setSorce(type);
  };

      
  const onChangeIndustry = (e) => {
    const type = e.target.value;
    setIndusury(type);
  };

  const onChangePriority = (e) => {
    const type = e.target.value;
    setPriority(type);
  };


  const onChangeStatus = (e) => {
    const type = e.target.value;
    setStatus(type);
  };



   useEffect(() => {

     
    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

    if (parseInt(userData.ID)>0) {
          setUser(userData);
        }

     }, []);


 
                 
  const CreateItem = (e) => {
    e.preventDefault();

  const recipeUrl = MAIN_URL+'?q=ui/save';

    let formData = new FormData();    //formdata object

    formData.append('LEAD_NAME', leadName);
    formData.append('DESIGNATIONS', dasingation);
    formData.append('T_NAME', "UILEAD_MASTER");
    formData.append('PHONE', phone);
    formData.append('MOBILE', phone);
    formData.append('EMAIL', email);
    formData.append('LEAD_SOURCE', sorce);
    formData.append('INDUSTRY', indusury);
    formData.append('INDUSTRY_NOTE', indusuryNote);
    formData.append('COMPANY_NAME', company);
    formData.append('COMPANY_EMAIL', email);
    formData.append('C_PHONE', phone);
    formData.append('LEAD_STATUS', status);
    formData.append('PRIORITY', priority);
    formData.append('MIN_ESTIMATE', min);
    formData.append('MAX_ESTIMATE', max);
    formData.append('STREET', street);
    formData.append('CITY', city);
    formData.append('STATE', state);
    formData.append('DESCRIPTION', description);
    formData.append('BUSSINESS_DESCRIPTIONS', description);
    formData.append('CURRENT_SOFTWARE', currentSoftware);
    formData.append('REASONE_TO_CHANGE', reasone);
    formData.append('IS_ACTIVE', 1);
    formData.append('CREATE_ON', Date.now()/1000);
    formData.append('CREATE_BY', user.ID);
    formData.append('MODIFY_ON', user.ID);
    formData.append('MODIFY_BY', user.ID);
    formData.append('LEAD_OWNER', user.ID);
    formData.append('CONSULTANT_ID', user.ID);
    formData.append('CONVERT_AMOUNT', 0);

      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Lead Saved");
              history.push("/lead/list");
            }
        })
        .catch(error => {
            console.log(error);
    });

  }


  return (
    <>
   
   <div className="main-wrapper">
  <div className="add-client">

  <div className="navbar two-action no-hairline">
  <div className="navbar-inner d-flex align-items-center justify-content-between">
    
  <SubHeader/>

  <div className="sliding custom-title">Add Lead</div>
  <div className="right">
  </div>
  </div>
  </div>

  <div className="page-content" style={{marginTop: "-16px"}}>
  <div className="list no-hairlines custom-form">
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Full Name</div>
  <div className="item-input-wrap">
  <input onChange={e => setLeadName(e.target.value)} value={leadName} type="text"/>
  </div>
  </div>
  </li>

  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Designation</div>
  <div class="item-input-wrap">
  <select onChange={onChangeDesignation}>
  <option value="">Select</option>
  <option value="Sale Manager">Sale Manager</option>
  <option value="Owner">Owner</option>
  <option value="Manager">Manager</option>
  <option value="Accountant">Accountant</option>
  <option value="Marketing Manager">Marketing Manager</option>
  <option value="Other">Other</option>
  </select>
  </div>
  </div>
  </li>

  
  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Lead Priority</div>
  <div class="item-input-wrap">
  <select onChange={onChangePriority}>
  <option value="">Select</option>
  <option value="Low priority">Low priority</option>
  <option value="Normal priority">Normal priority</option>
  <option value="High priority">High priority</option>
  <option value="Highest priority">Highest priority</option>
  </select>
  </div>
  </div>
  </li>

  
  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Lead Priority</div>
  <div class="item-input-wrap">
  <select onChange={onChangeStatus}>
  <option value="">Select</option>
  <option value="Waiting For Confirm">Waiting For Confirm</option>
  <option value="Pre Qulified">Pre Qulified</option>
  <option value="Not Qulified">Not Qulified</option>
  <option value="Not Connected">Not Connected</option>
  <option value="Contacted">Contacted</option>
  <option value="Contact In Futur">Contact In Futur</option>
  <option value="Attempted To Contact">Attempted To Contact</option>
  <option value="JunK Lead">JunK Lead</option>
  <option value="Interested">Interested</option>
  <option value="Demo">Demo</option>
  <option value="Installed">Installed</option>
  <option value="Payment Pending">Payment Pending</option>

  </select>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Phone</div>
  <div className="item-input-wrap">
  <input type="tel" onChange={e => setPhone(e.target.value)} value={phone}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Email</div>
  <div className="item-input-wrap">
  <input type="email" onChange={e => setEmail(e.target.value)} value={email}/>
  </div>
  </div>
  </li>


  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Min Estimated</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setMin(e.target.value)} value={min}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Max Estimated</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setMax(e.target.value)} value={max}/>
  </div>
  </div>
  </li>

  
  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Lead Source</div>
  <div class="item-input-wrap">
  <select onChange={onChangeSorce}>
  <option value="">Select</option>
  <option value="Visit">Visit</option>
  <option value="Refer">Refer</option>
  <option value="Massage">Massage</option>
  <option value="India Mart">India Mart</option>
  <option value="Instagram">Instagram</option>
  <option value="Justdail">Justdail</option>

  </select>
  </div>
  </div>
  </li>


  
  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Industry</div>
  <div class="item-input-wrap">
  <select onChange={onChangeIndustry}>
  <option value="">Select</option>
  <option value="Retailer">Retailer</option>
  <option value="Manufacturer">Manufacturer</option>
  <option value="Distributors">Distributors</option>
  </select>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Industry Note</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setIndusuryNote(e.target.value)} value={indusuryNote}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
    <hr style={{backgroundColor: "GrayText"}}/>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Company Name</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setCompany(e.target.value)} value={company}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Street</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setStreet(e.target.value)} value={street}/>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">City</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setCity(e.target.value)} value={city}/>
  </div>
  </div>
  </li>

    
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">State</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setState(e.target.value)} value={state}/>
  </div>
  </div>
  </li>
  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Pin</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setZip(e.target.value)} value={zip}/>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Country</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setCountry(e.target.value)} value={country}/>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Description</div>
  <div className="item-input-wrap">
  <textarea type="text" onChange={e => setDescription(e.target.value)} value={description}/>
  </div>
  </div>
  </li>


  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Current Software</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setCurrentSoftware(e.target.value)} value={currentSoftware}/>
  </div>
  </div>
  </li>
  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Reasone To Change</div>
  <div className="item-input-wrap">
  <textarea type="text" onChange={e => setReasone(e.target.value)} value={reasone}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">
  <button className="button button-big button-purple" onClick={CreateItem} type="button">{text}</button>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>




  </div>
  </div>

    </>

  );
}

export default NewLead;





