
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { useParams, useHistory } from 'react-router-dom';
import SubHeader from '../../Admin/comp/SubHeader';

function NewUser() {

  const history = useHistory();
  const { id } = useParams();


  const [user, setUser] = useState([]);
  const [text, setText] = useState("Save");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");




   useEffect(() => {

     
    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

    if (parseInt(userData.ID)>0) {
          setUser(userData);
        }


        if(id>0){
          LoadItem();
          setText("Update");
         }

     }, []);


 
     
   
  function LoadItem() {

    const Url = MAIN_URL+`?q=users/${id}`;

    axios.get(Url)
    .then(response => {
        console.log(response.data);
        if(response.data.SUCCESS > 0){
          setName(response.data.RS.NAME);
          setPhone(response.data.RS.PHONE);
          setEmail(response.data.RS.EMAIL);
          setPassword(response.data.RS.PASSWORD);


        }
    })
    .catch(error => {
        console.log(error);
    });

   }


                 
  const CreateUser = (e) => {
    e.preventDefault();

  const recipeUrl = MAIN_URL+'?url=db_in/save/USERS';

    let formData = new FormData();    //formdata object

    formData.append('PARTNER_ID', user.PARTNER_ID);
    formData.append('PARENT_ID', user.ID);
    formData.append('LEDGER_ID', 0);
    formData.append('NAME', name);
    formData.append('EMAIL', email);
    formData.append('PHONE', phone);
    formData.append('PASSWORD', password);
    formData.append('TYPE', "SALES");
    formData.append('REFER_CODE', "");
    formData.append('PIN', "");
    formData.append('ACCESS', "USER");
    formData.append('ACTIVE', 1);
    formData.append('REG_ON', Date.now()/1000);
    formData.append('LAST_LOGED', 0);

      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("User Saved");
              history.push("/admin/user-list");
            }
        })
        .catch(error => {
            console.log(error);
    });

  }


  const CheckPhone = (e) => {
    e.preventDefault();
    const loginUrl = MAIN_URL+`?q=users/check_phone/${phone}`;

    axios.get(loginUrl)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setPhone("")
        }
    })
    .catch(error => {
        console.log(error);
    });

  }


                   
  const Update = (e) => {
    e.preventDefault();

  const recipeUrl = MAIN_URL+'?url=db_in/updateId/USERS';

    let formData = new FormData();    //formdata object

    formData.append('ID', id);
    formData.append('NAME', name);
    formData.append('EMAIL', email);
    formData.append('PHONE', phone);
    formData.append('PASSWORD', password);
    formData.append('IS_ACTIVE', 1);;
      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Data Saved");
              history.push("/admin/reseller-list");
            }
        })
        .catch(error => {
            console.log(error);
    });

  }



  return (
    <>
   
   <div className="main-wrapper">
  <div className="add-client">

  <div className="navbar two-action no-hairline">
  <div className="navbar-inner d-flex align-items-center justify-content-between">
    
  <SubHeader/>

  <div className="sliding custom-title">Add New User</div>
  <div className="right">
  </div>
  </div>
  </div>

  <div className="page-content" style={{marginTop: "-16px"}}>
  <div className="list no-hairlines custom-form">
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Full Name</div>
  <div className="item-input-wrap">
  <input onChange={e => setName(e.target.value)} value={name} type="text"/>
  </div>
  </div>
  </li>


  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Phone</div>
  <div className="item-input-wrap">
  <input type="tel"  onBlur={CheckPhone} onChange={e => setPhone(e.target.value)} value={phone}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Email</div>
  <div className="item-input-wrap">
  <input type="email" onChange={e => setEmail(e.target.value)} value={email}/>
  </div>
  </div>
  </li>


  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Password</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setPassword(e.target.value)} value={password}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">

  {id>0 ? (
  <button className="button button-big button-purple" onClick={Update} type="button">{text}</button>
  ) : (
    <button className="button button-big button-purple" onClick={CreateUser} type="button">{text}</button>
    )}
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>




  </div>
  </div>

    </>

  );
}

export default NewUser;





