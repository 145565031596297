
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { Link, useHistory, useParams } from "react-router-dom";
import SubHeader from '../../Admin/comp/SubHeader';

function ClosedActivity() {

  const { id } = useParams();


  const [user, setUser] = useState([]);
  const [text, setText] = useState("Save");
  const [subject, setsubject] = useState("");
  const [result, setResult] = useState("Positive");


  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);
  
    if (parseInt(userData.ID)>0) {
      setUser(userData);
    }

  }, []);
  
  
  const chengeResult = (e) => {
    const type = e.target.value;
    setResult(type);
  };




   useEffect(() => {

     
    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

    if (parseInt(userData.ID)>0) {
          setUser(userData);
        }

     }, []);


 
                 
  const CreateActivity = (e) => {
    e.preventDefault();
    console.log();
  const recipeUrl = MAIN_URL+'?q=ui/edit';

    let formData = new FormData();    //formdata object

    formData.append('ID', id);
    formData.append('DESCRIPTION', subject);
    formData.append('T_NAME', "LEAD_ACTIVITY");
    formData.append('START_TIME', Date.now()/1000);
    formData.append('END_TIME', Date.now()/1000);
    formData.append('STATUS', 1);
    formData.append('MODIFY_BY', user.ID);
    formData.append('MODIFY_ON', Date.now()/1000);
    formData.append('IS_ACTIVE', "Y");

      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Closed Activity Successfully");
            }
        })
        .catch(error => {
            console.log(error);
    });

  }


  return (
    <>
   
   <div className="main-wrapper">
  <div className="add-client">

  <div className="navbar two-action no-hairline">
  <div className="navbar-inner d-flex align-items-center justify-content-between">
    
  <SubHeader/>

  <div className="sliding custom-title">Closed Activity</div>
  <div className="right">
  </div>
  </div>
  </div>

  <div className="page-content" style={{marginTop: "-16px"}}>
  <div className="list no-hairlines custom-form">
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Activity Subject</div>
  <div className="item-input-wrap">
  <textarea onChange={e => setsubject(e.target.value)} value={subject} type="text"/>
  </div>
  </div>
  </li>

  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Activity Type</div>
  <div class="item-input-wrap">
  <select onChange={chengeResult}>
  <option value="Positive">Positive</option>
  <option value="Neutral">Neutral</option>
  <option value="Negative">Negative</option>
  </select>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">
  <button className="button button-big button-purple" onClick={CreateActivity} type="button">{text}</button>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>

  </div>
  </div>

    </>

  );
}

export default ClosedActivity;





