
export const ActionTypes = {
    SET_PRODUCTS: "SET_PRODUCTS",
    SELECTED_PRODUCT: "SELECTED_PRODUCT",
    REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
    ADD_TO_CART: 'ADD_TO_CART',
    ADD_TO_ORDER: 'ADD_TO_ORDER',
    LOAD_CART: 'LOAD_CART',
    LOAD_ORDER: 'LOAD_ORDER',
    GET_NUMBERS_BASKET: 'GET_NUMBERS_BASKET',
    INCREASE_QUANTITY: 'INCREASE_QUANTITY',
    DECREASE_QUANTITY: 'DECREASE_QUANTITY',
    CLEAR_PRODUCT: 'CLEAR_PRODUCT',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    REMOVE_FROM_ORDER: 'REMOVE_FROM_ORDER',
    GET_TOTALS: "GET_TOTALS",
    CART_ITEM_QNTY: "CART_ITEM_QNTY",
    SIGN_IN: "SIGN_IN",
    SIGN_OUT: "SIGN_OUT",
    SELLER: "SELLER",
    SELLER_DATA: "SELLER_DATA",
    USER_DATA: "USER_DATA",
    ADMIN: "ADMIN",
    ORDER_ADDRESS: 'ORDER_ADDRESS',
    ORDER_CUSTOMER: 'ORDER_CUSTOMER',
    BIGMART_USER: 'BIGMART_USER'


};