
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../../Global/Urls';
import { Link, useParams } from 'react-router-dom';
import SubHeader from '../../Admin/comp/SubHeader';
function LeadDetails() {


  const { id } = useParams();
  
  const [lead, setLead] = useState([]);
  const [openList, setOpenList] = useState([]);
  const [closeList, setCloseList] = useState([]);
  const [load, setLoad] = useState(false);
  const [user, setuser] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {
          getOrderMaster();
          getOpenList();
          getCloseList();
        }

  }, []);
  



  
  function getOpenList() {
    const Url = MAIN_URL+`?q=uactivity/activity_list_lead_open/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setOpenList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }


  
   function getCloseList() {
    const Url = MAIN_URL+`?q=uactivity/activity_list_lead_closed/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setCloseList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   
  function getOrderMaster() {
      const Url = MAIN_URL+`?q=uleads/${id}`;
      axios.get(Url)
      .then(response => {
          if(response.data.SUCCESS > 0){
            setLead(response.data.RS);
            if(parseInt(response.data.RS.COMPANY_ID) > 0){
              setIsInstalled(true);
            }
            setLoad(true);
          }
      });

   }



 

  return (
    <>

    {load? (
      <>
         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>


<div className="sliding custom-title">Lead Details</div>
<div className="right mr-2">
<a href="#" className="link icon-only" data-toggle="modal" data-target="#edit-profile">
<i className="far fa-edit"></i>
</a>
</div>
</div>
</div>

<div className="page-content mt-0">

<div className="profile-header">

<div className="pro-user-det">
<div className="profile-name">{lead.LEAD_NAME}</div>
<div className="profile-designation">{lead.LEAD_SOURCE}</div>
<div className="profile-designation">

<Link to={`/activity/new/${lead.ID}`} className="button button-big button-purple" style={{border: "1px dotted #fff", fontSize: "12px"}}>Add New Activity</Link>
  </div>

</div>
</div>

<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "50%"}}>
<a id="Overview" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Overview</a>
</li>
<li className="nav-item" style={{width: "50%"}}>
<a id="Education" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Activity</a>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>
<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Overview">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
<div className="list">


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Contact Info</strong></p>
</div>
</div>

<div className="card-box" style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Phone</div>
<div className="item-after text-muted">{lead.PHONE}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Email</div>
<div className="item-after text-muted">{lead.EMAIL}</div>
</div>
</div>
</li>


</ul>
</div>

<div className="card-box"  style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Priority</div>
<div className="item-after text-muted">{lead.PRIORITY}</div>
</div>
</div>
</li>
 <li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Status</div>
<div className="item-after text-muted">{lead.LEAD_STATUS}</div>
</div>
</div>
</li>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Source</div>
<div className="item-after text-muted">{lead.LEAD_SOURCE}</div>
</div>
</div>
</li>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Type</div>
<div className="item-after text-muted">{lead.INDUSTRY}</div>
</div>
</div>
</li>
<li>


{lead.INDUSTRY_NOTE===null ? (
  <>

  </>
) : (
  <>
    <div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">{lead.INDUSTRY_NOTE}</div>
</div>
</div>
  </>
)}



</li>
</ul>
</div>


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Lead Owner Details</strong></p>
</div>
</div>


<div className="card-box"  style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Owner</div>
<div className="item-after text-muted">{lead.OWNER_NAME}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Creator</div>
<div className="item-after text-muted">{lead.CREATOR_NAME}</div>
</div>
</div>
</li>

<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Support Consultant</div>
<div className="item-after text-muted">{lead.CONSULTANT_NAME}</div>
</div>
</div>
</li>


<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">Last Modify</div>
<div className="item-after text-muted">{lead.MODIFY_NAME}</div>
</div>
</div>
</li>


</ul>
</div>





<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Company Details</strong></p>
</div>
</div>


<div className="card-box" style={{marginTop: "-15px"}}>
<ul>
<li>
<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">{lead.COMPANY_NAME}</div>
</div>
</div>
</li>


<li>





<div className="item-content">
<div className="item-inner d-flex justify-content-between">
<div className="item-title">City</div>
<div className="item-after text-muted">{lead.CITY}</div>

</div>
</div>


</li>
</ul>
</div>


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Company Address</strong></p>
<p>{lead.STREET} {lead.CITY}, {lead.STATE}, {lead.COUNTRY}, {lead.ZIP}</p>
</div>
</div>


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Business Details</strong></p>
<p>{lead.BUSSINESS_DESCRIPTIONS}</p>
</div>
</div>


<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Requirement</strong></p>
<p>{lead.DESCRIPTION}</p>
</div>
</div>

<div className="card-box pt-0 pb-0"  style={{marginTop: "-15px"}}>
<div className="block block-strong address-block">
<p className="mb-0"><strong>Reasone To Change</strong></p>
<p>{lead.REASONE_TO_CHANGE}</p>
</div>
</div>

</div>
</div>
</div>
<div id="pane-B" className="tab-pane fade" role="tabpanel" aria-labelledby="Education">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">
<ul className="experience-list">

{openList.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/activity/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-media mr-2" style={{marginTop: "12px"}}><img width="36" className="rounded-circle" src={`/assets/img/${item.TYPE.replace(" ", "_")}.jpg`} alt=""/></div>
                    <div className="item-inner appraisal-col">
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.OWNER_NAME}</strong></div>
                    {item.START_TIME>Date.now()/1000 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed/Open</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                      <span className="leave-type">{item.SUBJECT}</span> 
                      {item.START_TIME>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">Start Time: {(new Date(item.START_TIME*1000)).toLocaleString()}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}

                                        
{closeList.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-0px", borderTop: "1px solid red"}}>
                    <Link to={`/activity/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-media mr-2" style={{marginTop: "12px"}}><img width="36" className="rounded-circle" src={`/assets/img/${item.TYPE.replace(" ", "_")}.jpg`} alt=""/></div>
                    <div className="item-inner appraisal-col">
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.OWNER_NAME}</strong></div>
                    <div className="item-after">Closed</div>
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                      <span className="leave-type">{item.SUBJECT}</span> 
                    <span className="badge badge-success float-right leave-status">{item.TYPE}</span></div>
                    <div className="item-text leave-days">{(new Date(item.END_TIME*1000)).toLocaleString()}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}

</ul>
</div>
</div>
</div>
</div>

</div>

<div className="modal fade custom-modal-popup" id="edit-profile" tabindex="-1" role="dialog" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
<div className="modal-content">
<div className="modal-header">
<h6 className="modal-title" id="exampleModalLabel">Edit Profile</h6>
<button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div className="modal-body">
<div className="list no-hairlines custom-form mt-0">
<h6>Basic Informations</h6>
<ul>
<li className="item-content item-input mt-3">
<div className="item-inner">
<div className="item-title item-label">First Name</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Last Name</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Birth Date</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Gender</div>
<div className="item-input-wrap">
 <input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
</ul>
<h6>Contact Informations</h6>
<ul>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Address</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">State</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Country</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Pincode</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Phone Number</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Address</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
</ul>
<h6>Education Informations</h6>
<ul>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Institution</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Subject</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Starting Date</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Complete Date</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Degree</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Grade</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-input-wrap m-t-10">
<div className="add-more">
<a className="add-more-btn" href="#"><i className="fa fa-plus"></i> Add More Institute</a>
</div>
</div>
</div>
</li>
</ul>
<h6>Experience Informations</h6>
<ul>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Company Name</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Location</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Job Position</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Period From</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-title item-label">Period To</div>
<div className="item-input-wrap">
<input type="text"/>
<span className="input-clear-button"></span>
</div>
</div>
</li>
 <li className="item-content item-input">
<div className="item-inner">
<div className="item-input-wrap m-t-10">
<div className="add-more">
<a className="add-more-btn" href="#"><i className="fa fa-plus"></i> Add More Experience</a>
</div>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-inner">
<div className="item-input-wrap m-t-10">
<div className="row">
<div className="col-12">
<a className="tab-link button button-big button-purple w-100 btn" href="" data-dismiss="modal" aria-label="Close">Send</a>
</div>
</div>
</div>
</div>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
      </>
    ) : (
      <></>
      )}
   



    </>

  );
}

export default LeadDetails;





