
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { useParams, useHistory } from 'react-router-dom';
import SubHeader from './comp/SubHeader';

function LocationEdit() {

  const history = useHistory();
  const { id } = useParams();


  const [user, setUser] = useState([]);
  const [text, setText] = useState("Save");
  const [name, setName] = useState("");
  const [tittle, setTittle] = useState("");
  const [linkName, setLinkName] = useState("");
  const [type, setType] = useState("");
  const [pId, setPid] = useState(id);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [image, setImage] = useState("");


  
  const onChangeType = (e) => {
    const type = e.target.value;
    setType(type);
  };


   useEffect(() => {

     
    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

    if (parseInt(userData.ID)>0) {
          setUser(userData);
        }

          
   if(id>0){
    LoadItem();
    setText("Update");
   }



     }, []);


     
   
  function LoadItem() {

    const Url = MAIN_URL+`?q=locations/${id}`;

    axios.get(Url)
    .then(response => {
        console.log(response.data);
        if(response.data.SUCCESS > 0){
          setName(response.data.RS.NAME);
          setTittle(response.data.RS.TITTLE);
          setType(response.data.RS.TYPE);
          setKeywords(response.data.RS.KEYWORDS);
          setLinkName(response.data.RS.LINK_NAME);
          setPid(response.data.RS.P_ID);
          setDescription(response.data.RS.DESCRIPTION);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }


 
                 
  const CreateItem = (e) => {
    e.preventDefault();

  const recipeUrl = MAIN_URL+'?url=db_in/save/LOCATION';

    let formData = new FormData();    //formdata object

    formData.append('P_ID', pId);
    formData.append('TYPE', type);
    formData.append('NAME', name);
    formData.append('TITTLE', tittle);
    formData.append('LINK_NAME', linkName);
    formData.append('DESCRIPTION', description);
    formData.append('KEYWORDS', keywords);
    formData.append('IMAGE', Image);
    formData.append('IS_ACTIVE', 1);

    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Data Saved");
              history.push(`/admin/locations/${id}`);
            }
        })
        .catch(error => {
            console.log(error);
    });

  }


 
                 
  const Update = (e) => {
    e.preventDefault();

  const recipeUrl = MAIN_URL+'?url=db_in/updateId/LOCATION';

    let formData = new FormData();    //formdata object

    formData.append('ID', id);
    formData.append('P_ID', pId);
    formData.append('TYPE', type);
    formData.append('NAME', name);
    formData.append('TITTLE', tittle);
    formData.append('LINK_NAME', linkName);
    formData.append('DESCRIPTION', description);
    formData.append('KEYWORDS', keywords);
    formData.append('IMAGE', Image);
    formData.append('IS_ACTIVE', 1);
    
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Data Saved");
              history.push(`/admin/locations/${id}`);
            }
        })
        .catch(error => {
            console.log(error);
    });

  }


  return (
    <>
   
   <div className="main-wrapper">
  <div className="add-client">

  <div className="navbar two-action no-hairline">
  <div className="navbar-inner d-flex align-items-center justify-content-between">
    
  <SubHeader/>

  <div className="sliding custom-title">Add Location</div>
  <div className="right">
  </div>
  </div>
  </div>

  <div className="page-content" style={{marginTop: "-16px"}}>
  <div className="list no-hairlines custom-form">
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Place Name</div>
  <div className="item-input-wrap">
  <input onChange={e => setName(e.target.value)} value={name} type="text"/>
  </div>
  </div>
  </li>

  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Place Type</div>
  <div class="item-input-wrap">
  <select onChange={onChangeType}>
  <option value="">Select</option>
  <option value="Country">Country</option>
  <option value="State">State</option>
  <option value="City">City</option>
  </select>
  </div>
  </div>
  </li>

 
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Tittle</div>
  <div className="item-input-wrap">
  <input type="tel" onChange={e => setTittle(e.target.value)} value={tittle}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Link Name</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setLinkName(e.target.value)} value={linkName}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Description</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setDescription(e.target.value)} value={description}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Keywords</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setKeywords(e.target.value)} value={keywords}/>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Image Link</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setImage(e.target.value)} value={image}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">

  {id>0 ? (
  <button className="button button-big button-purple" onClick={Update} type="button">{text}</button>
  ) : (
    <button className="button button-big button-purple" onClick={CreateItem} type="button">{text}</button>
    )}
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>




  </div>
  </div>

    </>

  );
}

export default LocationEdit;





