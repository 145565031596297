
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { useParams, useHistory } from 'react-router-dom';
import SubHeader from './comp/SubHeader';

function ResellerNew() {

  const history = useHistory();
  const { id } = useParams();


  const [user, setUser] = useState([]);
  const [text, setText] = useState("Save");
  const [name, setName] = useState("");
  const [dasingation, setDasingation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("India");
  const [gstn, setGstn] = useState("");

  
  const onChangeDesignation = (e) => {
    const type = e.target.value;
    setDasingation(type);
  };


   useEffect(() => {

     
    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

    if (parseInt(userData.ID)>0) {
          setUser(userData);
        }

          
   if(id>0){
    LoadItem();
    setText("Update");
   }



     }, []);


     
   
  function LoadItem() {

    const Url = MAIN_URL+`?q=reseller/${id}`;

    axios.get(Url)
    .then(response => {
        console.log(response.data);
        if(response.data.SUCCESS > 0){
          setName(response.data.RS.CONTACT_NAME);
          setDasingation(response.data.RS.CONTACT_TYPE);
          setPhone(response.data.RS.PHONE_NO);
          setEmail(response.data.RS.EMAIL);
          setCompany(response.data.RS.COMPANY_NAME);
          setStreet(response.data.RS.ADDRESS);
          setState(response.data.RS.STATE);
          setCountry(response.data.RS.COUNTRY);
          setZip(response.data.RS.ZIP);
          setGstn(response.data.RS.GSTN);
          setCity(response.data.RS.CITY);

        }
    })
    .catch(error => {
        console.log(error);
    });

   }


 
                 
  const CreateItem = (e) => {
    e.preventDefault();

  const recipeUrl = MAIN_URL+'?url=db_in/save/RESELLER';

    let formData = new FormData();    //formdata object

    formData.append('CONTACT_NAME', name);
    formData.append('CONTACT_TYPE', dasingation);
    formData.append('PHONE_NO', phone);
    formData.append('EMAIL', email);
    formData.append('COMPANY_NAME', company);
    formData.append('ADDRESS', street);
    formData.append('COUNTRY', country);
    formData.append('STATE', state);
    formData.append('CITY', city);
    formData.append('ZIP', zip);
    formData.append('GSTN', gstn);
    formData.append('IS_ACTIVE', 1);;
      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Data Saved");
              history.push("/admin/reseller-list");
            }
        })
        .catch(error => {
            console.log(error);
    });

  }


 
                 
  const Update = (e) => {
    e.preventDefault();

  const recipeUrl = MAIN_URL+'?url=db_in/updateId/RESELLER';

    let formData = new FormData();    //formdata object

    formData.append('ID', id);
    formData.append('CONTACT_NAME', name);
    formData.append('CONTACT_TYPE', dasingation);
    formData.append('PHONE_NO', phone);
    formData.append('EMAIL', email);
    formData.append('COMPANY_NAME', company);
    formData.append('ADDRESS', street);
    formData.append('COUNTRY', country);
    formData.append('STATE', state);
    formData.append('CITY', city);
    formData.append('ZIP', zip);
    formData.append('GSTN', gstn);
    formData.append('IS_ACTIVE', 1);;
      
    axios.post(recipeUrl, formData)
        .then(response => {
            console.log(response.data);
            if(response.data.SUCCESS > 0){
              setText("Data Saved");
              history.push("/admin/reseller-list");
            }
        })
        .catch(error => {
            console.log(error);
    });

  }


  return (
    <>
   
   <div className="main-wrapper">
  <div className="add-client">

  <div className="navbar two-action no-hairline">
  <div className="navbar-inner d-flex align-items-center justify-content-between">
    
  <SubHeader/>

  <div className="sliding custom-title">Add Re-Seller</div>
  <div className="right">
  </div>
  </div>
  </div>

  <div className="page-content" style={{marginTop: "-16px"}}>
  <div className="list no-hairlines custom-form">
  <div className="card-box">
  <ul className="no-border pt-0 pb-0">
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Full Name</div>
  <div className="item-input-wrap">
  <input onChange={e => setName(e.target.value)} value={name} type="text"/>
  </div>
  </div>
  </li>

  <li class="item-content item-input">
  <div class="item-inner">
  <div class="item-title item-label">Contact Type</div>
  <div class="item-input-wrap">
  <select onChange={onChangeDesignation}>
  <option value="">Select</option>
  <option value="Sale Manager">Sale Manager</option>
  <option value="Owner">Owner</option>
  <option value="Manager">Manager</option>
  <option value="Accountant">Accountant</option>
  <option value="Marketing Manager">Marketing Manager</option>
  <option value="Other">Other</option>
  </select>
  </div>
  </div>
  </li>

 
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Phone</div>
  <div className="item-input-wrap">
  <input type="tel" onChange={e => setPhone(e.target.value)} value={phone}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Email</div>
  <div className="item-input-wrap">
  <input type="email" onChange={e => setEmail(e.target.value)} value={email}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Company Name</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setCompany(e.target.value)} value={company}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Street</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setStreet(e.target.value)} value={street}/>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">City</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setCity(e.target.value)} value={city}/>
  </div>
  </div>
  </li>

    
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">State</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setState(e.target.value)} value={state}/>
  </div>
  </div>
  </li>
  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Pin</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setZip(e.target.value)} value={zip}/>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">Country</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setCountry(e.target.value)} value={country}/>
  </div>
  </div>
  </li>

  
  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-title item-label">GSTN</div>
  <div className="item-input-wrap">
  <input type="text" onChange={e => setGstn(e.target.value)} value={gstn}/>
  </div>
  </div>
  </li>

  <li className="item-content item-input">
  <div className="item-inner">
  <div className="item-input-wrap m-t-10">

  {id>0 ? (
  <button className="button button-big button-purple" onClick={Update} type="button">{text}</button>
  ) : (
    <button className="button button-big button-purple" onClick={CreateItem} type="button">{text}</button>
    )}
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
  </div>




  </div>
  </div>

    </>

  );
}

export default ResellerNew;





