
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MAIN_URL } from '../Global/Urls';
import { Link, useParams } from 'react-router-dom';
import {useHistory} from "react-router-dom";
import SubHeader from './comp/SubHeader';

function ProductVersoning() {
    
  const history = useHistory();
  

  const { id } = useParams();
  
  const [product, setProduct] = useState([]);
  const [openList, setOpenList] = useState([]);
  const [closeList, setCloseList] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {

    const users = localStorage.getItem("user");
    const userData = JSON.parse(users);

  
    if (parseInt(userData.ID)>0) {
          getOrderMaster();
        }

  }, []);
  



  
  function getOpenList(ok) {
    const Url = MAIN_URL+`?q=product/ticket_open/${ok}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setOpenList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }


  
   function getCloseList() {
    const Url = MAIN_URL+`?q=product/product_verson_list/${id}`;
    axios.post(Url)
    .then(response => {
        if(response.data.SUCCESS > 0){
          setCloseList(response.data.RS);
        }
    })
    .catch(error => {
        console.log(error);
    });

   }

   
  function getOrderMaster() {
      const Url = MAIN_URL+`?q=product/${id}`;
      axios.get(Url)
      .then(response => {
          if(response.data.SUCCESS > 0){
            setProduct(response.data.RS);
            getCloseList();
            getOpenList(response.data.RS.PRODUCT_CODE);
            setLoad(true);
          }
      });

   }



 

  return (
    <>

    {load? (
      <>
         <div className="main-wrapper">
<div className="profile">

<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center justify-content-between">

<SubHeader/>

<div className="sliding custom-title">{product.PRODUCT_NAME}</div>
<div className="right mr-2">
<Link to={`/admin/product_details_create/${product.ID}`} className="link icon-only" data-toggle="modal" data-target="#edit-profile">
<i className="far fa-edit"></i>
</Link>
</div>
</div>
</div>

<div className="page-content mt-0">


<ul id="tabs" className="nav nav-tabs" role="tablist">
<li className="nav-item" style={{width: "50%"}}>
<a id="Overview" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Pending</a>
</li>
<li className="nav-item" style={{width: "50%"}}>
<a id="Education" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Completed</a>
</li>
</ul>


<div id="content" className="tab-content" role="tablist" style={{marginTop: "-16px"}}>
<div id="pane-A" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Overview">
<div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">







<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">



{openList.map(item => (
                    
                     

                 
                    <li className="col-12" style={{marginTop: "-1px", borderTop: "1px solid red"}}>
                    <Link to={`/ticket/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>{item.COMPANY_NAME.toUpperCase()}</strong></div>
                    {item.SCHEDULE_DATE>Date.now()/1000 ? (
                      <div className="item-after">Open</div>
                      ) : (
                      <div className="item-after">Missed</div>
                      )}
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">
                      {item.SUBJECT==="" || item.SUBJECT===null ? (
                        <>
                        </>
                      ) : (
                        <>
                        {item.SUBJECT}<br/>
                        </>
                      )}
                      {item.DESCRIPTION}</span> 

                      {item.SCHEDULE_DATE>Date.now()/1000 ? (
                      <span className="badge badge-warning float-right leave-status">{item.TYPE}</span>
                    ) : (
                      <span className="badge badge-danger float-right leave-status">{item.TYPE}</span>
                      )}
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.SCHEDULE_DATE*1000)).toLocaleString()}</div>
                    <div className="item-text leave-days">For : {item.OWNER_NAME}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                    
                                        
                                        ))}


</div>
</div>

</div>
</div>
<div id="pane-B" className="tab-pane fade" role="tabpanel" aria-labelledby="Education">
<div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
<div className="card-box" style={{padding: "0px"}}>
<div className="experience-box">

  
{closeList.map(item => (
                    
       
                    <li className="col-12" style={{marginTop: "-20px"}}>
                    <Link to={`/ticket/details/${item.ID}`}>
                    <div className="item-content d-flex pt-2 pb-2">
                    <div className="item-inner appraisal-col" style={{width: "100%"}}>
                    <div className="item-title-row d-flex align-items-center justify-content-between">
                    <div className="item-title"><strong>Module Name: {item.MODULE_NAME}</strong></div>
                    
                    </div>
                    <div className="item-subtitle leave-info d-flex align-items-center justify-content-between mt-0">
                    <span className="leave-type">
                      Note: {item.NOTE}</span> 
                      <span className="badge badge-danger float-right leave-status">{item.CREATE_ON}</span>
                    
                    </div>
                    <div className="item-text leave-days">{(new Date(item.CREATE_ON)).toLocaleString()}</div>
                    </div>
                    </div>
                    </Link>
                    </li>
                                        
                                        ))}


</div>
</div>
</div>
</div>

</div>

</div>
</div>
</div>
      </>
    ) : (
      <></>
      )}
   



    </>

  );
}

export default ProductVersoning;





