import React from 'react';
import { Switch, Route } from "react-router-dom";
import AcitivityReport from '../Admin/AcitivityReport';
import AdminHome from '../Admin/AdminHome';
import CompanyDetails from '../Admin/CompanyDetails';
import CompanyList from '../Admin/CompanyList';
import CompanyListExpiry from '../Admin/CompanyListExpiry';
import CompanyReport from '../Admin/CompanyReport';
import ConvertedReport from '../Admin/ConvertedReport';
import DealReport from '../Admin/DealReport';
import LeadListUser from '../Admin/LeadListUser';
import LeadReport from '../Admin/LeadReport';
import LocationEdit from '../Admin/LocationEdit';
import LocationList from '../Admin/LocationList';
import LocationNew from '../Admin/LocationNew';
import ProductVersoning from '../Admin/ProductVersoning';
import ProductVersoningEntry from '../Admin/ProductVersoningEntry';
import ResellerList from '../Admin/ResellerList';
import ResellerNew from '../Admin/ResellerNew';
import TicketReportClosed from '../Admin/TicketReportClosed';
import UserList from '../Admin/UserList';
import Home from '../Global/Home';
import ActivityList from '../Home/ActivityList';
import TicketList from '../Home/TicketList';
import AcitvityDetails from '../Leads/ui/AcitvityDetails';
import ActivityPending from '../Leads/ui/ActivityPending';
import ClosedActivity from '../Leads/ui/ClosedActivity';
import DealList from '../Leads/ui/DealList';
import LeadDetails from '../Leads/ui/LeadDetails';
import LeadList from '../Leads/ui/LeadList';
import NewActivity from '../Leads/ui/NewActivity';
import NewLead from '../Leads/ui/NewLead';
import TicketClosedEntry from '../Leads/ui/TicketClosedEntry';
import TicketDetails from '../Leads/ui/TicketDetails';
import Login from '../User/ui/Login';
import NewUser from '../User/ui/NewUser';
import Profile from '../User/ui/Profile';
import CompanyListUser from '../Leads/ui/CompanyListUser';
import CompanyListExpiryUser from '../Leads/ui/CompanyListExpiryUser';

export function Main() {

  return (
    <>

      <Switch>


        <Route path="/user/login">
          <Login />
        </Route>


        <Route path="/user/profile">
          <Profile />
        </Route>



        <Route path="/lead/new">
          <NewLead />
        </Route>



        <Route path="/user/new">
          <NewUser />
        </Route>


        <Route path="/usernew/:id">
          <NewUser />
        </Route>

        <Route path="/reseller/new">
          <ResellerNew />
        </Route>

        <Route path="/reseller/:id">
          <ResellerNew />
        </Route>

        <Route path="/lead/details/:id">
          <LeadDetails />
        </Route>


        <Route path="/admin/locations/:id">
          <LocationList />
        </Route>


        <Route path="/admin/locationsNew/:id">
          <LocationNew />
        </Route>


        <Route path="/admin/locationsEdit/:id">
          <LocationEdit />
        </Route>


        <Route path="/lead/list">
          <LeadList />
        </Route>


        <Route path="/lead/list_by_user/:id">
          <LeadListUser />
        </Route>


        <Route path="/activity/pending">
          <ActivityPending />
        </Route>


        <Route path="/activity/details/:id">
          <AcitvityDetails />
        </Route>



        <Route path="/ticket/details/:id">
          <TicketDetails />
        </Route>


        <Route path="/deal/list">
          <DealList />
        </Route>

        <Route path="/home/activity">
          <ActivityList />
        </Route>


        <Route path="/home/ticket">
          <TicketList />
        </Route>



        <Route path="/admin/home">
          <AdminHome />
        </Route>


        <Route path="/admin/lead">
          <LeadReport />
        </Route>

        <Route path="/admin/activity">
          <AcitivityReport />
        </Route>


        <Route path="/admin/deal">
          <DealReport />
        </Route>


        <Route path="/admin/company">
          <CompanyReport />
        </Route>


        <Route path="/admin/company-convert">
          <ConvertedReport />
        </Route>

        
        <Route path="/admin/company-list-expiry">
          <CompanyListExpiry />
        </Route>

        <Route path="/home/company-list-expiry">
          <CompanyListExpiryUser />
        </Route>


        <Route path="/admin/reseller-list">
          <ResellerList />
        </Route>

        <Route path="/home/company-list">
          <CompanyListUser />
        </Route>


        <Route path="/admin/company-list">
          <CompanyList />
        </Route>



        <Route path="/admin/ticket-closed">
          <TicketReportClosed />
        </Route>


        <Route path="/admin/user-list">
          <UserList />
        </Route>


        <Route path="/admin/product_details/:id">
          <ProductVersoning />
        </Route>


        <Route path="/admin/companyDetails/:id">
          <CompanyDetails />
        </Route>


        <Route path="/admin/product_details_create/:id">
          <ProductVersoningEntry />
        </Route>


        <Route path="/ticket/closed/:id">
          <TicketClosedEntry />
        </Route>


        <Route path="/activity/new/:id">
          <NewActivity />
        </Route>


        <Route path="/activity/closed/:id">
          <ClosedActivity />
        </Route>



        <Route path="/">
          <Home />
        </Route>

      </Switch>

    </>

  );
}


export default Main;

